import React, { useEffect, useState } from 'react';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  Input,
  Button,
  message,
  Checkbox,
  Tooltip,
  InputNumber,
  Descriptions,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import axios from 'axios';
import { round } from 'lodash';
import { from } from 'rxjs';
import { DownloadOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const SpecialSettlements: React.FC<SpecialSettlementsProps> = ({ thm, branches, banks, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [sectors, setSectors] = useState<any>([]);
  const [selectedSector, setSelectedSector] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [appMonto, setAppMonto] = useState(0);
  const [metros, setMetros] = useState(0);
  const [horas, setHoras] = useState(0);
  const [veces, setVeces] = useState(0);
  const [kilos, setKilos] = useState(0);
  const [unidades, setUnidades] = useState(0);
  const [hect, setHect] = useState(1);
  const [montod, setMontoD] = useState(1);
  const [esVigente, setEsVigente] = useState(false);
  const [ramoTitle, setRamoTitle] = useState('Seleccione un propósito');
  const [blockMonto, setBlockMonto] = useState(false);
  const [hideSub, setHideSub] = useState(false);
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [credit, setCredit] = useState<any[any]>([]);
  const [processId, setProcessId] = useState<number>(0);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const selectedInstitution = institutions?.find((i) => i.id === 9);
  const petro = useSelector((state: State) => state.coin.petro);
  const ut = useSelector((state: State) => state.ut.ut);

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente || auth?.user?.contribuyente;
  // const showAll = auth.user?.institucion?.id === 9;
  const showAll = true;
  const cpuBranches = ['111', '123', '124'];

  useEffect(() => {
    if (auth?.user?.tipoUsuario === 4 && !id) {
      if (auth?.user?.contribuyente?.id) {
        history.push(`/dashboard/impuestos/especiales/${auth?.user?.contribuyente?.id}`);
      } else {
        history.push('/dashboard');
      }
    }
  }, []);

  const fetchCredit = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/settlements/contributor/credit/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCredit(response.data.credit);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las compensaciones');
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if (taxPayer) fetchCredit(taxPayer.id);
  }, [taxPayer]);

  useEffect(() => {
    if (auth?.user?.tipoUsuario !== 4) {
      setEsVigente(false);
    }
  }, []);

  useEffect(() => {
    handleGetBranchSector();
  }, []);

  const resetCounters = () => {
    setMetros(0);
    setHect(0);
    setUnidades(0);
    setKilos(0);
    setMontoD(0);
    setAppMonto(0);
    setHoras(0);
    setVeces(0);
  };

  useEffect(() => {
    form.setFieldsValue({ ramo: '' });
    form.setFieldsValue({ subramo: '' });
    form.setFieldsValue({ aplicacion: '' });
    form.setFieldsValue({ ut: 0 });
    form.setFieldsValue({ monto: 0 });
    form.setFieldsValue({ metros: 0 });
    form.setFieldsValue({ kilos: 0 });
    form.setFieldsValue({ hect: 0 });
    form.setFieldsValue({ unidades: 0 });
    form.setFieldsValue({ horas: 0 });
    form.setFieldsValue({ veces: 0 });
    setData({
      ...data,
      ramo: '',
      subramo: '',
      aplicacion: '',
      ut: 0,
      monto: 0,
      metros: 0,
      kilos: 0,
      hect: 0,
      unidades: 0,
      horas: 0,
      veces: 0,
    });
    setBlockMonto(false);
    resetCounters();
  }, [selectedSector]);

  useEffect(() => {
    form.setFieldsValue({ subramo: '' });
    form.setFieldsValue({ aplicacion: '' });
    form.setFieldsValue({ ut: 0 });
    setData({ ...data, subramo: '', aplicacion: '', ut: 0 });
    resetCounters();
  }, [data.ramo]);

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/impuestos/especiales');
    }
    if (auth.user?.institucion?.nombreCorto !== 'SAGAS' && auth.user?.institucion?.nombreCompleto !== 'CPU') {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data.ramo) {
      let bran = branches.find((b) => b.id === data.ramo);
      setRamoTitle(bran?.descripcion || 'Seleccione un propósito');
      if ([498, 960].includes(data.ramo)) {
        form.setFieldsValue({ ut: round((form.getFieldValue('monto') || 0) * (100 / (petro * ut)), 2) });
        setBlockMonto(false);
      } else if (appMonto && appMonto > 0) {
        form.setFieldsValue({ monto: appMonto });
        setBlockMonto(true);
        form.setFieldsValue({ ut: round(round(appMonto || 0, 2) * (100 / (petro * ut)), 2) });
      } else {
        if (data.subramo) {
          let subr = bran?.subramos?.find((s) => s.id === data.subramo);
          form.setFieldsValue({
            monto:
              subr?.monto && subr?.monto > 0
                ? round(
                    subr?.monto *
                      (metros ? metros : 1) *
                      (kilos ? kilos : 1) *
                      (unidades ? unidades : 1) *
                      (horas ? horas : 1) *
                      (veces ? veces : 1),
                    2
                  )
                : 0,
            // (
            // subr.descripcion.includes('hectáreas') ? round((bran?.subramos[0]?.monto || 0),2) + round( (subr?.monto * (hect - 1)) ,2)
            // : round(subr?.monto * (metros ? metros : 1),2) ) : 0
          });
          setBlockMonto(subr?.monto && subr?.monto > 0 ? true : false);
          form.setFieldsValue({
            ut:
              (subr?.montout && subr?.montout > 0 ? subr?.montout : round(round(subr?.monto || 0, 2) * (100 / (petro * ut)), 2)) *
              (metros ? metros : 1) *
              (kilos ? kilos : 1) *
              (unidades ? unidades : 1) *
              (horas ? horas : 1) *
              (veces ? veces : 1),
          });
        } else {
          if ((bran?.subramos?.length || 0) > 0) {
            form.setFieldsValue({ monto: 0 });
            setBlockMonto(false);
            form.setFieldsValue({ ut: 0 });
          } else {
            let minCase = round(5 * round((petro * ut) / 100, 2), 2);
            form.setFieldsValue({
              monto:
                data.ramo === 770 &&
                (bran?.monto && bran?.monto > 0
                  ? round(
                      bran?.monto *
                        (metros ? metros : 1) *
                        (kilos ? kilos : 1) *
                        (unidades ? unidades : 1) *
                        (horas ? horas : 1) *
                        (veces ? veces : 1) *
                        (montod ? montod / 100 : 1),
                      2
                    )
                  : 0) < minCase
                  ? minCase
                  : bran?.monto && bran?.monto > 0
                  ? round(
                      bran?.monto *
                        (metros ? metros : 1) *
                        (kilos ? kilos : 1) *
                        (unidades ? unidades : 1) *
                        (horas ? horas : 1) *
                        (veces ? veces : 1) *
                        (montod ? montod / 100 : 1),
                      2
                    )
                  : 0,
            });
            setBlockMonto(bran?.monto && bran?.monto > 0 ? true : false);
            form.setFieldsValue({
              ut:
                data.ramo === 770 &&
                (bran?.montout && bran?.montout > 0
                  ? bran?.montout
                  : round(round(bran?.monto || 0, 2) * (100 / (petro * ut)), 2)) *
                  (metros ? metros : 1) *
                  (kilos ? kilos : 1) *
                  (unidades ? unidades : 1) *
                  (horas ? horas : 1) *
                  (veces ? veces : 1) *
                  (montod ? montod / 100 : 1) <
                  5
                  ? 5
                  : (bran?.montout && bran?.montout > 0
                      ? bran?.montout
                      : round(round(bran?.monto || 0, 2) * (100 / (petro * ut)), 2)) *
                    (metros ? metros : 1) *
                    (kilos ? kilos : 1) *
                    (unidades ? unidades : 1) *
                    (horas ? horas : 1) *
                    (veces ? veces : 1) *
                    (montod ? montod / 100 : 1),
            });
          }
        }
      }
    } else {
      setRamoTitle('Seleccione un propósito');
    }
  }, [branches, data.ramo, data.subramo, montod, metros, kilos, unidades, horas, veces, hect, appMonto, data.ut, data.monto]);

  const handleAppChange = (id) => {
    const appM = branches.find((b) => b.id === data.ramo)?.aplicaciones?.find((b) => b.id === id)?.monto;
    if (appM) {
      setAppMonto(appM);
    } else {
      setAppMonto(0);
    }
  };

  const reverseOperation = async () => {
    if(processId){
      setLoading(true);
      try {
        const response = await axios.delete(`${server}/settlements/incomplete/${processId}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.info('Operación reversada.');
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al reversar operación.');
      } finally {
        setLoading(false);
      }
    }
  };

  const submit = async () => {
    let values = await form.validateFields();
    const checkF = (i) => {
      return files ? files.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let notP: Array<any> = [];
    if (values.pagos && values.pagos?.length > 0) {
      values.pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = values.pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    const hasAllMandatoryTakings =
      auth?.user?.tipoUsuario === 4
        ? esVigente
          ? true
          : notP && notP?.length > 0
          ? !notP.some((p, i) => p.check === false)
          : true
        : true;
    const ingresado =
      esVigente || !values.pagos
        ? 0
        : round(
            values.pagos.reduce((prev, current) => prev + current.costo, 0),
            2
          );
    let ingresadoCompensacion = values.compensaciones
      ? credit
          ?.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          ?.reduce((prev, current) => prev + current.disponible, 0)
      : 0;
    console.log(esVigente, 'VIGENTE');
    console.log(ingresado, 'ingresado');
    console.log(round(values.monto, 2), 'rounded');
    if (!esVigente && round(values.monto, 2) > ingresado + ingresadoCompensacion) {
      message.error('El monto de los pagos debe ser igual o mayor al monto de la liquidación');
    } else if (!hasAllMandatoryTakings && auth?.user?.tipoUsuario === 4) {
      message.error('Debe ingresar un comprobante por cada pago');
    } else {
      if (values.subramo === '') {
        delete values.subramo;
      }
      if (values.aplicacion === '') {
        delete values.aplicacion;
      }
      const ramo = (showAll ? branches : branches.filter((b) => cpuBranches.includes(b.codigo || '')))
        .filter((b) => b.liquidacionEspecial)
        .filter((b) => b.sectorID === sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo)
        .filter((element) => element.descripcion === values.ramo)[0]?.id;
      values.fechaCancelada = moment(new Date(), 'DD/MM/YYYY');
      values.fechaCancelada = {
        month: values.fechaCancelada.locale('es').format('MMMM'),
        year: parseInt(values.fechaCancelada.locale('es').format('YYYY')),
      };
      values.monto = +(+values.monto / petro).toFixed(8);
      values.impuestos = [
        {
          fechaCancelada: values.fechaCancelada,
          ramo: ramo,
          subramo: values.subramo,
          monto: values.monto,
          aplicacion: values.aplicacion,
        },
      ];
      values.tipoDocumento = taxPayer.tipoDocumento;
      values.documento = taxPayer.documento;
      values.tipoContribuyente = taxPayer.tipoContribuyente;
      values.rim = values.rim ? values.rim : taxPayer.documento;
      if (!esVigente && values.pagos) {
        values.pagos
          .filter((p) => p)
          .forEach((p) => {
            Object.keys(p).forEach((k) => {
              if (moment.isMoment(p[k])) {
                p[k] = p[k].toISOString();
              }
            });
          });
      }
      if (values.pagos) {
        for (let i = 0; i < values.pagos.length; i++) {
          values.pagos[i] = { ...values.pagos[i], id_usuario: auth?.user?.id };
        }
      }
      values.esVigente = esVigente;
      values.credit =
        credit && credit?.length > 0
          ? credit.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          : null;
      // delete values.metros;
      // delete values.sector;
      // delete values.ut;
      // delete values.unidades;
      setLoading(true);
      try {
        const response = await axios.post(`${server}/settlements/special`, values, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setProcessId(response?.data?.solicitud?.id);
        const fd = new FormData();
        let fileCount = 0;
        if (files)
          Object.keys(files).forEach((k) => {
            fd.append('recaudos', files[k]);
            fileCount++;
          });
        if (fileCount > 0) {
          const resp = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
        }
        message.success('Liquidación creada de manera exitosa');
        if (auth?.user?.tipoUsuario !== 4) history.push('/dashboard/impuestos/especiales');
        else history.push('/dashboard');
        if (response.status === 201 && response.data.solicitud.recibo) {
          const win = window.open(response.data.solicitud.recibo, '_blank');
          win?.focus();
        }
        form.resetFields();
        history.push('/dashboard/impuestos/pagar');
      } catch (e) {
        message.error(e?.response?.data?.error || e?.response?.data?.message || 'Error al crear liquidación');
        reverseOperation();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGetBranchSector = async () => {
    try {
      const response = await axios.get(`${server}/branches/sectors`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setSectors(response.data.data.sectors);
    } catch (e) {
      message.error('No se pudieron obtener los sectores');
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Liquidaciones de Timbres y/o Tasas'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex' }}>
        {/* <div style={{marginTop: '5px'}}>
          <Button  icon={<DownloadOutlined/>} target='_blank' type='primary'
          style={{ marginTop: 0 }} download href='https://sut-anzoategui.s3.us-east-2.amazonaws.com/leyes/GACETA+LEY+DE+TASAS+Y+TIMBRES+FISCALES++FEBRERO+2022.pdf'>Descargar Ley de Tasas y Timbres Fiscales</Button>
        </div>
        <div style={{marginTop: '5px', marginLeft:'5px'}}>
          <Button  icon={<DownloadOutlined/>} target='_blank' type='primary'
          style={{ marginTop: 0 }} download href='https://sut-anzoategui.s3.us-east-2.amazonaws.com/leyes/Ley+sobre+minerales+no+metalicos+del+estado+Anzoategui.pdf'>Descargar Ley de Minerales no Metálicos</Button>
        </div> */}
        <div style={{ marginTop: '5px' }}>
          <Button
            icon={<DownloadOutlined />}
            target='_blank'
            type='primary'
            style={{ marginTop: 0 }}
            download
            href='https://sat-anzoategui.s3.us-east-2.amazonaws.com/leyes/LIBRO+DE+CONTROL+DE+TIMBRES+FISCALES+DEL+EDO.+ANZOATEGUI.xlsx'
          >
            Libro de Control de Timbres Fiscales
          </Button>
        </div>
      </div>
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            console.log(c);
            console.log(v);
            const ramo = (showAll ? branches : branches.filter((b) => cpuBranches.includes(b.codigo || '')))
              .filter((b) => b.liquidacionEspecial)
              .filter((b) => b.sectorID === sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo)
              .filter((element) => element.descripcion === v.ramo)[0]?.id;
            form.setFieldsValue(v);
            setData({ ...v, ramo });
          }}
        >
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Sucursal
              </Typography.Title>
            </Divider>
          )}
          {(auth?.user?.tipoUsuario !== 4 ||
            (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
            <Row gutter={24}>
              <Col xs={24} xl={24}>
                <Form.Item label='Sucursal' name='rim' rules={[{ required: false, message: 'Debe ingresar la sucursal' }]}>
                  <Select placeholder='Seleccione la sucursal'>
                    {taxPayer?.sucursales?.map((s) => (
                      <Select.Option value={s.referenciaMunicipal} key={s.id}>
                        {s.referenciaMunicipal} - {s.denomComercial} - {s.email} - {s.nombreRepresentante} - {s.direccion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Liquidación de Tasa o Impuesto
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            <Col xs={24} xl={24}>
              <Form.Item
                label='Seleccione una categoría'
                name='sector'
                rules={[{ required: true, message: 'Debe seleccionar una categoría' }]}
              >
                <Select placeholder='Seleccione una categoría' showSearch onChange={(value) => setSelectedSector(value)}>
                  {sectors
                    .filter((s) => ![20, 46].includes(s.id_sector_ramo))
                    .filter((s) => s.id_sector_ramo !== (auth?.user?.tipoUsuario !== 4 ? 0 : 45))
                    .map((b) => (
                      <Select.Option value={b.descripcion} key={b.id_sector_ramo}>
                        {b.descripcion}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={24}>
              <Tooltip title={ramoTitle}>
                <Form.Item
                  label='Seleccione timbre fiscal requerido'
                  name='ramo'
                  rules={[{ required: true, message: 'Debe ingresar el propósito' }]}
                >
                  <Select placeholder='Seleccione un propósito' showSearch>
                    {(showAll ? branches : branches.filter((b) => cpuBranches.includes(b.codigo || '')))
                      .filter((b) => b.liquidacionEspecial)
                      .filter((b) => b.sectorID === sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo)
                      .map((b) => (
                        <Select.Option value={b.descripcion} key={b.id}>
                          {b.descripcion}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Tooltip>
            </Col>
            {data.ramo && data.ramo !== '' && (branches.find((b) => b.id === data.ramo)?.aplicaciones?.length || 0) > 0 && (
              <Col xs={24} xl={16}>
                <Form.Item
                  label='Aplicación del timbre'
                  name='aplicacion'
                  rules={[{ required: true, message: 'Debe escoger una aplicación para el timbre' }]}
                >
                  <Select placeholder='Seleccione una aplicación para el timbre' onChange={(e) => handleAppChange(e)}>
                    {branches
                      .find((b) => b.id === data.ramo)
                      ?.aplicaciones?.map((b) => (
                        <Select.Option value={b.id} key={b.id}>
                          {b.descripcion}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {data.ramo &&
              data.ramo !== '' &&
              appMonto === 0 &&
              (branches.find((b) => b.id === data.ramo)?.subramos?.length || 0) > 0 && (
                <Col xs={24} xl={16}>
                  <Form.Item
                    label='Caso Específico'
                    name='subramo'
                    rules={[{ required: true, message: 'Debe ingresar caso específico' }]}
                  >
                    <Select placeholder='Seleccione un caso específico'>
                      {branches
                        .find((b) => b.id === data.ramo)
                        ?.subramos?.map((b) => (
                          <Select.Option value={b.id} key={b.id}>
                            {b.descripcion}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            {((data.ramo &&
              (branches.find((b) => b.id === data.ramo)?.descripcion?.includes('m2') ||
                branches.find((b) => b.id === data.ramo)?.descripcion?.includes('m3'))) ||
              (data.ramo &&
                (branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('m2') ||
                  branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('m3')))) && (
              <Col xs={24} xl={16}>
                <Form.Item
                  label='Metros'
                  name='metros'
                  rules={[{ required: true, message: 'Debe ingresar la cantidad de metros' }]}
                >
                  <InputNumber
                    placeholder='Metros'
                    onChange={(value) => setMetros(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {((data.ramo &&
              (branches.find((b) => b.id === data.ramo)?.descripcion?.includes('x kilo') ||
                branches.find((b) => b.id === data.ramo)?.descripcion?.includes('x kilo'))) ||
              (data.ramo &&
                (branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('x kilo') ||
                  branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('x kilo')))) && (
              <Col xs={24} xl={16}>
                <Form.Item label='Kilos' name='kilos' rules={[{ required: true, message: 'Debe ingresar la cantidad de kilos' }]}>
                  <InputNumber
                    placeholder='Kilos'
                    onChange={(value) => setKilos(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {((data.ramo && branches.find((b) => b.id === data.ramo)?.descripcion?.includes('x unidad')) ||
              (data.ramo && branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('x unidad'))) && (
              <Col xs={24} xl={16}>
                <Form.Item
                  label='Unidades'
                  name='unidades'
                  rules={[{ required: true, message: 'Debe ingresar la cantidad de unidades autorizadas' }]}
                >
                  <InputNumber
                    placeholder='Unidades'
                    onChange={(value) => setUnidades(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {((data.ramo && branches.find((b) => b.id === data.ramo)?.descripcion?.includes('x hora')) ||
              (data.ramo && branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('x hora'))) && (
              <Col xs={24} xl={16}>
                <Form.Item label='Horas' name='horas' rules={[{ required: true, message: 'Debe ingresar la cantidad de horas' }]}>
                  <InputNumber
                    placeholder='Horas'
                    onChange={(value) => setHoras(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {((data.ramo && branches.find((b) => b.id === data.ramo)?.descripcion?.includes('x veces')) ||
              (data.ramo && branches.find((b) => b.id === data.ramo)?.subramos[0]?.descripcion?.includes('x veces'))) && (
              <Col xs={24} xl={16}>
                <Form.Item label='Veces' name='veces' rules={[{ required: true, message: 'Debe ingresar la cantidad de veces' }]}>
                  <InputNumber
                    placeholder='Veces'
                    onChange={(value) => setVeces(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {data.ramo &&
              data.subramo &&
              branches
                .find((b) => b.id === data.ramo)
                ?.subramos.find((s) => s.id === data.subramo)
                ?.descripcion?.includes('hectáreas') && (
                <Col xs={24} xl={16}>
                  <Form.Item
                    label='Hectáreas'
                    name='hect'
                    rules={[{ required: true, message: 'Debe ingresar la cantidad de hectáreas' }]}
                  >
                    <InputNumber
                      placeholder='Hectáreas'
                      onChange={(value) => setHect(value || 0)}
                      defaultValue={1}
                      min={1}
                      // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
            {data.ramo && branches.find((b) => b.id === data.ramo)?.descripcion?.includes('%') && (
              <Col xs={24} xl={16}>
                <Form.Item
                  label='Monto a declarar'
                  name='montod'
                  rules={[{ required: true, message: 'Debe ingresar el monto a declarar' }]}
                >
                  <InputNumber
                    placeholder='Monto a declarar'
                    onChange={(value) => setMontoD(value || 0)}
                    // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                    style={{ width: '100%', fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
            )}
            {data.ramo && [498, 960].includes(data.ramo) && (
              <Col xs={24} xl={16}>
                <Form.Item
                  label='Descripción del caso'
                  name='desc'
                  rules={[{ required: true, message: 'Debe especificar la descripción del caso' }]}
                >
                  <Input placeholder='Descripción' />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Monto a pagar'
                name='monto'
                rules={[{ required: true, message: 'Debe ingresar el monto de la liquidación' }]}
              >
                <InputNumber
                  placeholder='Monto'
                  disabled={blockMonto}
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Total VTCMMV' name='ut'>
                <InputNumber
                  placeholder='VTCMMV'
                  // disabled={!([498,960].includes(data?.ramo))}
                  disabled={true}
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* {showAll && <Checkbox checked={!esVigente} onChange={(e) => setEsVigente(!e.target.checked)}>Incluir Pago</Checkbox>} */}
          {/* {showAll && auth.user?.tipoUsuario === 4 && <Button type='primary' onClick={() => setEsVigente(!esVigente)} >{esVigente ? 'Incluir Pago' : 'No Incluir Pago'}</Button> } */}
          {!esVigente && showAll && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Datos de Pago
                </Typography.Title>
              </Divider>
              {credit && credit?.length > 0 && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Text ellipsis strong style={{ marginLeft: 3 }}>
                      Compensaciones Disponibles
                    </Typography.Text>
                  </Divider>
                  <Col xs={24} xl={20}>
                    <Form.Item rules={[{ required: false }]} name='compensaciones'>
                      <Select mode='multiple' placeholder='Compensaciones Disponibles'>
                        {credit?.map((c) => (
                          <Select.Option value={c.id_credito_fiscal} key={c.id_credito_fiscal}>
                            Nro {c.resolucion} - Bs. {c.disponible}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <MultipleTransactions
                selectedInstitution={institutions?.find((i) => i.id === 9)}
                form={form}
                data={data}
                maxCred={0}
                files={files}
                setFiles={setFiles}
                percentTax={false}
                mineralTax={sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo === 20}
                credit={credit}
                bomberos={sectors.find((s) => s.descripcion === selectedSector)?.id_sector_ramo === 10}
                peajes={false}
              />
              <Row>
                <Divider orientation='left' style={{ marginLeft: 0 }}>
                  <Typography.Title level={4}>Total Ingresado</Typography.Title>
                </Divider>
                <Col xl={24} xs={24}>
                  <Descriptions bordered>
                    <Descriptions.Item label='Total Ingresado'>
                      Bs.{' '}
                      {formatCurrency(
                        (data.pagos
                          ?.filter((p) => p)
                          ?.map((p) => p.costo)
                          ?.reduce((c, v) => c + v, 0) || 0) +
                          Number(
                            form.getFieldValue('compensaciones')
                              ? credit
                                  ?.filter((c) =>
                                    form.getFieldValue('compensaciones')?.find((com) => com === c.id_credito_fiscal)
                                  )
                                  ?.reduce((prev, current) => prev + current.disponible, 0)
                              : 0
                          )
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Divider orientation='left' style={{ marginLeft: 0 }}>
                  <Typography.Title level={4}>Monto Restante</Typography.Title>
                </Divider>
                <Col xl={24} xs={24}>
                  <Descriptions bordered>
                    <Descriptions.Item label='Total Restante' style={{ color: 'red' }}>
                      Bs.{' '}
                      {formatCurrency(
                        ((data.pagos
                          ?.filter((p) => p)
                          ?.map((p) => p.costo)
                          ?.reduce((c, v) => c + v, 0) || 0) +
                          Number(
                            form.getFieldValue('compensaciones')
                              ? credit
                                  ?.filter((c) =>
                                    form.getFieldValue('compensaciones')?.find((com) => com === c.id_credito_fiscal)
                                  )
                                  ?.reduce((prev, current) => prev + current.disponible, 0)
                              : 0
                          ) -
                          +parseFloat(data.monto || '0').toFixed(2)) *
                          -1
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              {auth.user?.tipoUsuario !== 4 && (
                <Button
                  onClick={() =>
                    history.push('/dashboard/impuestos/especiales', {
                      contribuyentes: (history.location.state as any)?.contribuyentes,
                    })
                  }
                >
                  Atrás
                </Button>
              )}
            </Col>
            <Row gutter={12}>
              <Col>
                <Button loading={loading} type='primary' onClick={form.submit}>
                  {esVigente ? 'Liquidar Timbre Fiscal' : 'Pagar Timbre Fiscal'}
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SpecialSettlements);

interface SpecialSettlementsProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
