import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { Table, Button, Card, Row, Input, Col, DatePicker } from "antd";
import { connect } from "react-redux";
import { State, States } from 'sigt';
import { useWindowDimensions } from "../../utils/hooks";
import Highlighter from "react-highlight-words"
import { ColumnsType } from 'antd/lib/table';
import "../../assets/css/components/TableOfficial.css";
import moment, { Moment } from "moment";
import axios from "axios";
const server = process.env.REACT_APP_SERVER_URL;

const TableMovements: React.FC<PropsTable> = ({ thm, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();
  const [data, setData] =  useState<DataArray[]>([])
  const [keys, setKeys] = useState<Moment[]>([]);
  const [filterStatus, SetfilterStatus] = useState<any[]>([]);

  const getType = (text: string) => {
    if(text.includes('aprobar_rc')) return 'Registro de contribuyente aprobado.' ;
    else if(text.includes('procesar_rc')) return 'Registro de contribuyente iniciado.' ;
    else if(text.includes('rechazar_rc')) return 'Registro de contribuyente rechazado.' ;
    else if(text.includes('object')) return 'Tramite procesado.' ;
    else return text;
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if(dataIndex === "consumido")
      setSearchText(selectedKeys[0]);
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
    return(
      <Row style={{width:250, padding: 8}} gutter={[8,8]}>
        <Col span={24}>
          <DatePicker.RangePicker style={{width:'100%'}} format='DD/MM/YYYY' placeholder={['Fecha inicio ','Fecha fin']} size='small' 
            onChange={(values)=>{ setSelectedKeys(values) }} value={selectedKeys} 
          />
        </Col>
        <Col span={12}>
          <Button style={{width:'100%'}} type='primary' icon={<SearchOutlined />} size='small' onClick={() => { confirm(); }}>
            Buscar
          </Button>
        </Col>
        <Col span={12} >
          <Button style={{width:'100%'}} onClick={() => clearFilters()} size='small'>
            Restablecer
          </Button>
        </Col>
      </Row> 
    )},
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />
    ),
    onFilter: (value, record) => {
      return moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') 
        || moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
    },  
  });


  const columns: ColumnsType<any> = [
    {
      title: "Funcionario",
      dataIndex: "nombre_completo",
      key: "nombre_completo",
      ...getColumnSearchProps("nombre_completo")
    },
    {
      title: "Correo",
      dataIndex: "nombre_de_usuario",
      key: "nombre_de_usuario",
      ...getColumnSearchProps("nombre_de_usuario")
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      ...getColumnSearchProps("telefono")
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_movimiento',
      align: 'center',
      ...getColumnFilterDate('fecha_movimiento'),
      render: (text, record) => ( <>{ moment.utc(text).format('DD/MM/YYYY' )}</> )
    },  
    {
      title: "Tipo de Proceso",
      dataIndex: "tipo",
      key: "tipo",
      ...getColumnSearchProps("tipo")
    },   
  ] 

  useEffect(() => {
    aux();
    // eslint-disable-next-line
  }, []);

  const aux = async() => {
    if(auth.token) {
      try {
        let dat : any[] = [];
          const response = await axios.get(
            `${server}/procedures/users/movements`,
            { headers: { Authorization: `Bearer ${auth.token}` } }
          );
          response.data.movimientos.forEach(ele => {
            let tipo = getType(ele.tipo_movimiento);
            dat.push({...ele, tipo})
          })
        setData(dat)
      } catch (e) {
        throw e;
      }
    }
    setLoading(false);
  }

  return (
    <Card style={{ height: '100%' }} title='Bitácora' 
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: "#fff" }} bodyStyle={{ overflowY: 'auto' }}>
      <Table
        style={{ height: '100%' }}
        size='middle'
        loading={loading}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={data}
      />
    </Card>
  );
};
const mapStateToProps = ({ auth, thm }: State) => ({ auth, thm });

export default connect( mapStateToProps, { })(TableMovements);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface DataArray {
  nombre_completo: string;
  nombre_de_usuario: string;
  telefono: string;
  fecha_movimiento: string;
  tipo_movimiento: string;
}
