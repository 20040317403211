import React, { useState } from 'react';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../../utils/hooks';
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Button,
  message,
  Select,
  Table,
  Tag,
  Divider,
  Typography,
  Descriptions,
  Tooltip,
  Modal,
  Alert,
} from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, DownloadOutlined, DeleteFilled, CloudDownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

const InternalPayments: React.FC<InternalPaymentsProps> = ({ thm, auth, banks }) => {
  const [searching, setSearching] = useState(false);
  const [payment, setPayment] = useState<any>(null);
  const [downloading, setDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const canDelete = [90, 35, 41, 42, 100, 98];
  const canDeleteAlways = [90, 35, 42, 100, 98];

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const StatusConfig = {
    iniciado: { color: 'default', name: 'Iniciado' },
    validando: { color: 'warning', name: 'Validando pago' },
    ingresardatos: { color: 'cyan', name: 'En espera de pago' },
    finalizado: { color: 'green', name: 'Finalizado' },
    enproceso: { color: 'geekblue', name: 'En proceso' },
    enrevision: { color: 'lime', name: 'En Revision' },
    enrevision_analista: { color: 'lime', name: 'En Revision Gerente' },
    enrevision_gerente: { color: 'lime', name: 'En Revision Gerente General' },
    porrevisar: { color: 'cyan', name: 'Por Revisar' },
    atendido: { color: 'warning', name: 'Atendido' },
    visto: { color: 'default', name: 'Visto' },
    aprobado: { color: 'green', name: 'Aprobado' },
    negado: { color: 'error', name: 'Negado' },
    error: { color: 'error', name: 'Error' },
    inspeccion: { color: 'lime', name: 'Inspección' },
  };

  const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12, 35, 6];

  const columns: ColumnsType<any> = [
    {
      title: 'Banco de Origen',
      dataIndex: 'banco',
      render: (id) => <span>{banks.find((b) => b.id === id)?.nombre}</span>,
    },
    {
      title: 'Banco de Destino',
      dataIndex: 'bancoDestino',
      render: (id) => <span>{banks.find((b) => b.id === id)?.nombre}</span>,
    },
    {
      title: 'Referencia',
      dataIndex: 'referencia',
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'aprobado',
      render: (ap) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={ap ? 'green' : 'warning'}>
          {ap ? 'Aprobado' : 'En Revisión'}
        </Tag>
      ),
    },
    {
      title: 'Concepto',
      dataIndex: 'concepto',
      render: (cp) => <b>{cp}</b>,
    },
    {
      title: 'Comprobante',
      dataIndex: 'url_archivo_soporte',
      render: (soporte) => { return soporte ? <Button type='primary' target='_blank' href={soporte || ''}>Comprobante</Button> : null },
    },
  ];

  const liqsColumns: ColumnsType<any> = [
    {
      dataIndex: 'fecha',
      title: 'Fecha',
      render: (text) => (text?.month ? `${firstLetterMayus(text.month)} ${text.year}` : undefined),
    },
    {
      dataIndex: 'ramo',
      title: 'Ramo',
      render: (ramo) => (ramo ? <span>{ramo}</span> : <span>Liquidación sin ramo</span>),
    },
    {
      dataIndex: 'monto',
      title: 'Monto',
      render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
    },
  ];

  const requestColumns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: ['contribuyente', 'documento'],
      render: (doc, r) => (
        <span>
          {r.contribuyente?.tipoDocumento}-{doc}
        </span>
      ),
    },
    // {
    //   title: 'Documento de Identidad.',
    //   dataIndex: 'referenciaMunicipal',
    //   render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    // },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>{tipo ? tipo : 'N/A'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(parseFloat(monto))}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
  ];

  const proceduresColumns: ColumnsType<any> = [
    {
      title: 'Codigo',
      dataIndex: 'codigoTramite',
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaCreacion',
      render: (text, record) => <span>{moment(text).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Trámite',
      dataIndex: 'nombreTramiteCorto',
      render: (text, record) => <Tooltip title={record.nombreTramiteLargo}>{text}</Tooltip>,
    },
    {
      title: 'Costo',
      dataIndex: 'costo',
      render: (text) => <span>Bs. {formatCurrency(+text)}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={StatusConfig[value].color}>
          {StatusConfig[value].name}
        </Tag>
      ),
    },
    {
      title: 'Planilla',
      dataIndex: 'planilla',
      render: (url) => (
        <Button type='primary' target='_blank' href={url} icon={<DownloadOutlined />}>
          Descargar
        </Button>
      ),
    },
  ];

  const agreementColumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Fecha de Aprobación',
      dataIndex: 'fechaAprobacion',
      render: (fecha) => (fecha ? <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span> : <b>NO APROBADO</b>),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(+monto)}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (estado) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(estado).color}>
          {getState(estado).name}
        </Tag>
      ),
    },
    {
      title: 'Comprobante',
      dataIndex: 'id',
      render: (_, record) =>
      record.url_archivo_soporte ? (
        <Row gutter={24}>
          {record.url_archivo_soporte &&             
            <Col>
              <Button type='primary' target='_blank' href={record.url_archivo_soporte || ''}>
                Comprobante
              </Button>
            </Col>
          }
        </Row>
      ) : null,
    },
  ];

  const search = async () => {
    try {
      const values = await form.validateFields();
      setSearching(true);
      setPayment(null);
      const params = new URLSearchParams(values);
      const response = await axios.get(`${server}/banks/reference/search?${params}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setPayment(response.data.pagos[0]);
      message.success('Pagos obtenidos de manera exitosa');
      form.resetFields();
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al buscar pagos');
    } finally {
      setSearching(false);
    }
  };

  const deleteProcedure = async () => {
    try {
      const values = await form2.validateFields();
      setRemoving(true);
      await axios.patch(`${server}/banks/payment/one/one/${payment.id}`, values, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success('Pago reversado exitosamente');
      setPayment(null);
      setIsDeleting(false);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al eliminar pago');
    } finally {
      setRemoving(false);
    }
  };

  const downloadTicket = async (id: number) => {
    if (!downloading) {
      setDownloading(true);
      const complete = message.loading('Generando Recibo...');
      try {
        const response = await axios.post(`${server}/settlements/receipt/${id}/application`, null, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Recibo generado de manera exitosa.');
        window.open(response.data.url, '_blank');
      } catch (e) {
        message.error('Error al generar Recibo para la solicitud.');
      } finally {
        setDownloading(false);
        complete();
      }
    }
  
  };


  const contrib = payment?.procedimiento?.contribuyente || payment?.procedimiento?.datos?.usuario?.contribuyente;

  return (
    <Card
      style={{ height: '100%' }}
      title='Consulta de Pagos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form layout='vertical' form={form} onFinish={() => search()}>
        <Row gutter={24}>
          <Col xs={24} xl={7}>
            <Form.Item
              label='Banco de Destino'
              name='banco'
              rules={[{ required: true, message: 'Debe ingresar el banco de destino' }]}
            >
              <Select placeholder='Banco de Destino'>
                {/* {banks
                  .filter((b) => sedematBanks.includes(b.id))
                  .map((b) => (
                    <Select.Option key={b.id} value={b.id}>
                      {b.nombre}
                    </Select.Option>
                  ))} */}
                  <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option>
                  <Select.Option value={3}>Banco Nacional de Crédito</Select.Option>
                  <Select.Option value={14}>Banco del Tesoro </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='Referencia' name='referencia' rules={[{ required: true, message: 'Debe ingresar la referencia' }]}>
              <Input placeholder='Referencia' maxLength={20} />
            </Form.Item>
          </Col>
          <Col xl={6} xs={12}>
            <Button
              onClick={form.submit}
              style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
              loading={searching}
              icon={<SearchOutlined />}
              type='primary'
            >
              Buscar
            </Button>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={payment ? [payment] : []}
              bordered
              loading={searching}
              rowKey='id'
              pagination={false}
            />
          </Col>
        </Row>
        {payment && (
          <>
            {contrib && (
              <>
                <Divider orientation='left' style={{ marginLeft: -10 }}>
                  <Typography.Title level={4}>Datos del Contribuyente</Typography.Title>
                </Divider>
                <Descriptions bordered column={{ xl: 24 }}>
                  <Descriptions.Item label='Documento de Identidad' span={12}>
                    {contrib?.tipoDocumento ? `${contrib?.tipoDocumento}-${contrib?.documento}` : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Razón Social' span={12}>
                    {contrib?.razonSocial || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Denominación Comercial' span={12}>
                    {contrib?.denomComercial || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Siglas' span={12}>
                    {contrib?.siglas || 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Tipo de Contribuyente' span={12}>
                    {contrib?.tipoContribuyente || 'N/A'}
                  </Descriptions.Item>
                  {/* {parseFloat(contrib?.creditoFiscal || '0') && (
                    <Descriptions.Item label='Credito Fiscal'>
                      Bs. {formatCurrency(parseFloat(contrib?.creditoFiscal || '0'))}
                    </Descriptions.Item>
                  )} */}
                </Descriptions>
              </>
            )}
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Procedimiento asociado al pago</Typography.Title>
            </Divider>
            {payment.concepto === 'IMPUESTO' && (
              <>
                <Divider style={{ margin: 5, marginLeft: -5, marginTop: -15, marginBottom: 10 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Solicitud
                  </Typography.Text>
                </Divider>
                <Row>
                  <Col span={24}>
                    <Table
                      style={{ marginTop: width < 1200 ? 38 : 0 }}
                      columns={requestColumns}
                      dataSource={payment.procedimiento ? [payment.procedimiento] : []}
                      rowKey='id'
                      bordered
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Divider style={{ margin: 5, marginLeft: -5, marginTop: 15, marginBottom: 10 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Liquidaciones
                  </Typography.Text>
                </Divider>
                <Row>
                  <Col span={24}>
                    <Table
                      bordered
                      pagination={false}
                      columns={liqsColumns}
                      dataSource={payment.procedimiento?.liquidaciones}
                      rowKey='id'
                    />
                  </Col>
                </Row>
                <Divider style={{ margin: 5, marginLeft: -5, marginTop: 15, marginBottom: 10 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Recibo
                  </Typography.Text>
                </Divider>
                <Row>
                  <Col>
                    <Button
                      type='primary'
                      style={{ marginBottom: 10 }}
                      loading={downloading}
                      icon={<CloudDownloadOutlined />}
                      onClick={() => downloadTicket(payment?.procedimiento?.id || 0)}
                    >
                      {'Descargar Recibo'}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {payment.concepto === 'TRAMITE' && (
              <>
                <Table bordered pagination={false} columns={proceduresColumns} dataSource={[payment.procedimiento]} rowKey='id' />
              </>
            )}
            {payment.concepto === 'CONVENIO' && (
              <>
                <Table bordered pagination={false} columns={agreementColumns} dataSource={[payment.procedimiento]} rowKey='id' />
              </>
            )}
            {payment.procedimiento?.pagos?.length > 1 && (
              <>
                <Divider orientation='left' style={{ marginLeft: -10 }}>
                  <Typography.Title level={4}>Otros pagos asociados</Typography.Title>
                </Divider>
                <Table
                  columns={columns}
                  dataSource={payment.procedimiento.pagos.filter((p) => p.id !== payment.id)}
                  bordered
                  rowKey='id'
                  pagination={false}
                />
              </>
            )}
          </>
        )}
      </Form>
      {((canDelete.includes(auth.user?.institucion?.cargo?.id || 0) &&
        payment?.procedimiento?.estado === 'finalizado' &&
        moment().utc().format('DD/MM/YYYY') ===
          moment(payment?.procedimiento?.fechaInsercion, 'MM-DD-YYYY').utc().format('DD/MM/YYYY')) ||
        canDeleteAlways.includes(auth.user?.institucion?.cargo?.id || 0)) && (
        <Button type='primary' icon={<DeleteFilled />} onClick={() => setIsDeleting(true)} style={{ marginTop: 10 }}>
          Eliminar
        </Button>
      )}
      {/* <Modal
        visible={isDeleting}
        onCancel={() => setIsDeleting(false)}
        closable={!removing}
        okButtonProps={{ loading: removing }}
        cancelButtonProps={{ disabled: removing }}
        onOk={() => deleteProcedure()}
      >
        <Row>
          <Col span={24}>
            <Alert
              style={{ marginTop: 25 }}
              type='warning'
              message='Eliminar un pago implica eliminar el procedimiento asociado y toda la información asociada a este. Se debe registrar el pago nuevamente por parte del usuario o de un cajero.'
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Alert
              style={{ marginTop: 10, fontWeight: 'bolder' }}
              type='error'
              message='ESTA ACCIÓN ES POTENCIALMENTE DESTRUCTIVA Y NO ES REVERSIBLE.'
            />
          </Col>
        </Row>
      </Modal> */}
      <Modal
        width='400px'
        bodyStyle={{ height: '45vh', overflowY: 'scroll' }}
        centered
        footer={null}
        title='Rechazar Pago'
        maskClosable
        visible={isDeleting}
        onCancel={() => setIsDeleting(false)}
        closable={!removing}
        okButtonProps={{ loading: removing }}
        cancelButtonProps={{ disabled: removing }}
      >
        <Form form={form2} layout='vertical' onFinish={deleteProcedure}>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Form.Item
                label='Motivo de rechazo'
                name='descripcion'
                rules={[{ required: true, message: 'Debe especificar el motivo de rechazo' }]}
              >
                <Input.TextArea placeholder='Motivo de rechazo' />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 16 }} gutter={16}>
            <Col span={16}>
              <Button type='primary' htmlType='submit'>
                Rechazar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, banks: state.bk.banks });

export default connect(mapStateToProps)(InternalPayments);

interface InternalPaymentsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  banks: States.Banks['banks'];
}
