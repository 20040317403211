import React, { useState } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { Card, Row, Col, Form, Input, Select, Table, Tag, Button, message } from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { SearchOutlined, CreditCardFilled, EyeFilled } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router';
import { setRequests } from '../../redux/actions/requests';
import Petro from '../Icons/Petro';
import { Utils } from '../../utils/validators';
import { getCurrency } from '../../utils/currency';
const server = process.env.REACT_APP_SERVER_URL;

const PendingRequests: React.FC<PendingRequestsProps> = ({ thm, auth, setReduxRequests, reduxReqs }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);

  const petro = useSelector((state: State) => state.coin.petro);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  const canPay: number[] = [22, 25, 40, 41, 53, 56, 90, 98, 100, 104];

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
      <Select.Option value='C'>C</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: ['contribuyente', 'documento'],
      render: (doc, r) => (
        <span>
          {r?.contribuyente?.tipoDocumento}-{doc}
        </span>
      ),
    },
    // {
    //   title: 'Documento de Identidad.',
    //   dataIndex: 'referenciaMunicipal',
    //   render: (rim) => <span>{rim ? rim : 'N/A'}</span>
    // },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>{tipo ? tipo : 'N/A'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (fecha ? <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span> : 'Sin Fecha'),
    },
    {
      title: `Monto (${getCurrency()})`,
      align: 'center',
      dataIndex: 'montoPetro',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto (Bs.)',
      dataIndex: 'monto',
      render: (monto, record) => (
        <span>{`Bs. ${formatCurrency(Math.round(monto || +(record.montoPetro * petro).toFixed(2)))}`}</span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
  ];

  columns.push({
    title: 'Acciones',
    dataIndex: 'id',
    render: (id, tax) => (
      <Button
        icon={
          (auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
          (tax.estado === 'ingresardatos' || tax.estado === 'finalizado') ? (
            <CreditCardFilled />
          ) : (
            <EyeFilled />
          )
        }
        type='primary'
        onClick={() => history.push(`/dashboard/impuestos/pagar/${id}`, { solicitudes: reduxReqs })}
      >
        {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
        (tax.estado === 'ingresardatos' || tax.estado === 'finalizado')
          ? 'Pagar'
          : 'Ver'}
      </Button>
    ),
  });

  const search = async () => {
    const values = await form.validateFields();
    try {
      setSearching(true);
      const response = await axios.get(
        `${server}/settlements/instances/${values.tipoContribuyente}?documento=${values.doc}&tipoDocumento=${tipoDocumento}${
          values.rim ? `&referencia=${values.rim}` : ''
        }`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setReduxRequests(response.data.solicitudes);
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Liquidaciones Pendientes'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form layout='vertical' form={form}>
        <Row gutter={24}>
          <Col xl={7} xs={24}>
            <Form.Item
              label='Tipo de Contribuyente'
              name='tipoContribuyente'
              rules={[{ required: true, message: 'Debe indicar el tipo de contribuyente' }]}
            >
              <Select placeholder='Tipo de Contribuyente'>
                <Select.Option value='NATURAL'>Natural</Select.Option>
                <Select.Option value='JURIDICO'>Jurídico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item
              label='Documento de Identidad'
              name='doc'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
              normalize={Utils.normalize.isCNumber}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          {/* <Col xs={24} xl={7}>
            <Form.Item label='Documento de Identidad.' name='rim'>
              <Input placeholder='Documento de Identidad.' />
            </Form.Item>
          </Col> */}
          <Col xl={3} xs={12}>
            <Button
              onClick={() => search()}
              style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
              loading={searching}
              icon={<SearchOutlined />}
              type='primary'
            >
              Buscar
            </Button>
          </Col>
          <Col span={24}>
            <Table
              style={{ marginTop: width < 1200 ? 38 : 0 }}
              columns={columns}
              dataSource={reduxReqs}
              rowKey='id'
              bordered
              loading={searching}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, reduxReqs: state.req.requests });

export default connect(mapStateToProps, { setReduxRequests: setRequests })(PendingRequests);

interface PendingRequestsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  setReduxRequests: any;
  reduxReqs: States.Requests['requests'];
}
