import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import { Table, Form, Row, Col, Select, Input, DatePicker, Divider, Button, Typography, InputNumber, Card, Checkbox, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { States, State, Institucion } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';

const MultipleTransactions: React.FC<MultipleTransactionsProps> = ({ banks, selectedInstitution, form, data, maxCred, selectedReqType, maxCredR, auth, files, setFiles, percentTax, mineralTax, credit, bomberos, peajes, isLate }) => {
  // BOD, BANESCO Y BNC
  const sedematBanks = [1, 2, 3, 14, 22, 19, 23, 25, 11, 12, 35, 6];
  const recaudosRef = useRef<RefObject<HTMLImageElement>[]>([]);

  useEffect(()=>{
    if( form.getFieldValue('pagos')?.length > recaudosRef.current?.length ) {
      let diff = form.getFieldValue('pagos')?.length - recaudosRef.current?.length
      for(let i=0; i<diff; i++){
        recaudosRef.current.push(createRef<HTMLImageElement>())    
      }
    }
    else if( form.getFieldValue('pagos')?.length < recaudosRef.current?.length ) {
      let diff = recaudosRef.current?.length - form.getFieldValue('pagos')?.length 
      for(let i=0; i<diff; i++){
        recaudosRef.current.splice(-1,1);  
      }
    }
  },[form]);

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) recaudosRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/excel.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/pdf.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          recaudosRef.current[i]?.current?.setAttribute('src', require('../../../assets/images/file.png'));
          recaudosRef.current[i]?.current?.style.setProperty('width', '80px');
          recaudosRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    recaudosRef.current[i] = createRef();
  };

  const docTypeSelect = (field) => ( 
    <Form.Item noStyle name={[field.name, 'tipoDocumento']} rules={[{ required: true, message: 'Debe ingresar el tipo de documento del contribuyente' }]} >
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const prefixSelect = (field) => (
    <Form.Item noStyle name={[field.name, 'prefijo']} >
      <Select>
        <Select.Option value='0424'>0424</Select.Option>
        <Select.Option value='0414'>0414</Select.Option>
        <Select.Option value='0412'>0412</Select.Option>
        <Select.Option value='0426'>0426</Select.Option>
        <Select.Option value='0416'>0416</Select.Option>
      </Select>
    </Form.Item>
  );

  const bankColumns: ColumnsType<any> = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left'
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta'
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular'
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    }
  ];

  const firstListItem = (fields, add) => {
    if(fields.length === 0 && (!credit || credit?.length === 0 || isLate)) {
      // setTimeout(() => add(), 50);
      add();
    }
  };

  const alreadyUsedCredit = () => data.pagos && data.pagos.find(p => p && p.metodoPago === 'CREDITO_FISCAL');
  const alreadyUsedCreditR = () => data.pagos && data.pagos.find(p => p && p.metodoPago === 'CREDITO_FISCAL_RETENCION');

  const cobrayaText = [
    {
      id:11,
      codigo:'0102',
      text:`Se solicita desde la página web de Banco de Venezuela o 
      SMS 2661 o 2662 + cedula`
    },
    {
      id:3,
      codigo:'0191',
      text:`Pestaña Pagos/Pago Móvil -> Generador Clave C2P`
    },{
      id:23,
      codigo:'0108',
      text:`Dinero Rápido -> QuieroGenerar Clave de Compra -> Generar `
    },{
      id:12,
      codigo:'0114',
      text:`Mi Pago Bancaribe -> Clave Mi Pago Comercio`
    },{
      id:16,
      codigo:'0115',
      text:`Envíe al 278 el texto CLAVE V su número de cédula (o E si es extranjero).`
    },{
      id:14,
      codigo:'0163',
      text:`Debe ingresar a la aplicación “Tesoro Pago Móvil (P2P) y solicitar en esa APP la clave.
      O envíe al 2383 el texto "Comercio" + V + su número de cédula + (una coordenada a elegir)`
    },{
      id:2,
      codigo:'0134',
      text:`Generar Claves Dinámicas a través del portal de Mis Solicitudes banesco o enviando un SMS al número 2846 de la siguiente forma:
      Coloca la palabra clave dinamica, el tipo de literal: V, E ó J, según sea el caso, y el número de cédula o RIF. Recuerda dejar un espacio entre cada dato. Ejemplo: clave dinamica V 00000000`
    },{
      id:22,
      codigo:'0138',
      text:`Tu DineroYa -> Generar Token.
      Envíe al 1470 el texto: CLAVE V su número de cédula. (o E si es extranjero).
      Tu Plaza en Línea`
    },{
      id:29,
      codigo:'0174',
      text:`Pago Plus -> Clave C2P`
    },{
      id:35,
      codigo:'0169',
      text:`Envíe al 22622 el texto: PAGAR`
    },{
      id:32,
      codigo:'0157',
      text:`Envíe al 78910 el texto COBROD2`
    },{
      id:25,
      codigo:'0168',
      text:`Pag@Ya de Bancrecer. Ahí encuentra la clave C2P.`
    },{
      id:6,
      codigo:'0171',
      text:`Pago Activo. Ahí encuentra la clave C2P.
      Envíe al 228486 el texto C2P+TIPO DE DOCUEMENTO+NUMERO DE DOCUMENTO`
    },{
      id:33,
      codigo:'0151',
      text:`Pago Móvil BFC. Ahí encuentra la clave C2P`
    },{
      id:5,
      codigo:'0172',
      text:`Se obtiene al entrar a Bancamiga Suite y después a Pago Móvil.`
    },{
      id:19,
      codigo:'0105',
      text:`Via APP de Tpago en el botón Clave C2P o a través de un SMS al 24024 con la palabra ...`
    },{
      id:27,
      codigo:'0177',
      text:`Envíe al 326200 si es Movistar o Digitel o 78900 si es Movilnet, el texto SERVICIO C2P`
    },{
      id:8,
      codigo:'0175',
      text:`En el menú principal da clic a Pago Móvil, escoge la opción Código de Autorización - C2P, por último, selecciona Solicitud del Código de Autorización.`
    },{
      id:36,
      codigo:'0137',
      text:`Sofitasa Pago Móvil. Ahí encuentra la clave P2C.
      Sofinet personas`
    },{
      id:7,
      codigo:'0166',
      text:`Banca Móvil o e-banca Personas generarr Clave Dinámica.`
    },{
      id:9,
      codigo:'0128',
      text:`Web Móvil → Solicitud del Código de Autorización
      Envíe al 100102 el texto: C2P + PAGO + (la clave de transacciones especiales de Pago Móvil) →
      Le enviarán la Clave C2P.`
    },{
      id:20,
      codigo:'0156',
      text:`Via APP o web 100% Internet Personas y a través de la Web Móvil. Generar Clave Dinamica`
    },{
      id:24,
      codigo:'0104',
      text:`Venezolano de Crédito AppBvc -> Clave C2P
      Venezolano Online`
    },
  ];

  return ( 
    <React.Fragment>
      <Table bordered pagination={false} style={{ marginBottom: 15 }} columns={bankColumns} dataSource={
        selectedInstitution?.cuentasBancarias?.filter((c) => !(Number(c.id) === 6 || Number(c.id) === 7)).filter(
          (b) => mineralTax ? b.usoRamo?.includes('Minerales') : ( percentTax ? b.usoRamo?.includes('2x100') && (Number(b.id) !== 1 && Number(b.id) !== 2) : b.usoRamo?.includes('Timbres') && (Number(b.id) !== 4 && Number(b.id) !== 5))
          ).map((c) => 
        ({numeroCuenta: c.numeroCuenta, 
        documento: `${(c.rif) ? 'RIF: ' : 'Cedula: '}${(c.rif || c.cedula)}`, nombreTitular: c.nombreTitular, banco: banks?.find(b => b.id === c.banco)?.nombre, key: c.id })
      )} />
      <Form.List name='pagos'>
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => { 
              const ref = React.createRef<HTMLInputElement>();
              return (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Transacción {index + 1}
                  </Typography.Text>
                </Divider>
                <Form.Item required key={field.key} style={{ width: '100%', margin: 0 }}>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                    <Col xl={8} xs={24}>
                      <Form.Item label='Método de Pago' name={[field.name, 'metodoPago']} rules={[{ required: true, message: 'Debe indicar el método de pago' }]}>
                        <Select placeholder='Método de Pago' onChange={(v) => {
                          if(v==='COBRAYA') {
                          let pagos = data.pagos
                          pagos[field.key] = { ...pagos[field.key], costo: data.monto || 0, metodoPago: v };
                          form.setFieldsValue({pagos: pagos});
                          }
                        }}>
                          <Select.Option value='TRANSFERENCIA'>Transferencia</Select.Option>
                          { auth?.user?.tipoUsuario !== 4  && <Select.Option value='PUNTO DE VENTA'>Punto de Venta</Select.Option>}
                          <Select.Option value='DEPOSITO'>Depósito</Select.Option>
                          {/* <Select.Option value='COBRAYA'>Pago Móvil C2P</Select.Option> */}
                          {/* {(selectedReqType !== 'RETENCION' && maxCred && maxCred > 0)  && <Select.Option disabled={alreadyUsedCredit()} value='CREDITO_FISCAL'>Credito Fiscal por compesanción</Select.Option>}
                          {(selectedReqType !== 'RETENCION' && maxCredR && maxCredR > 0)  && <Select.Option disabled={alreadyUsedCreditR()} value='CREDITO_FISCAL_RETENCION'>Credito Fiscal por retención</Select.Option>} */}
                        </Select>
                      </Form.Item>
                    </Col>
                    {data.pagos && (data.pagos[field.name]?.metodoPago !== 'EFECTIVO' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' ) && 
                    <Col xs={24} xl={8}>
                      <Form.Item label='Banco de Origen' name={[field.name, 'banco']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                        {banks ? 
                        <Select placeholder='Banco de Origen' onChange={(v) => {
                          if(data.pagos[field.name]?.metodoPago === 'COBRAYA')       
                            notification.info({
                              placement:'topLeft',
                              bottom: 50,
                              duration: 0,
                              style:{color:'black', border:'4px black solid', 
                              // backgroundColor:'#fafafa' 
                            },
                              message:`Como obtener clave C2P para ${banks.find((b) => b.id === v)?.nombre}`,
                              description:cobrayaText.find((el) => el.id === v)?.text || 'Seleccione Banco de Origen'
                            });
                            }}>
                          {banks?.filter((b) => data.pagos[field.name]?.metodoPago === 'COBRAYA' ? cobrayaText.find((c) => c.id === b.id) : true)?.map((b, i) =>
                            <Select.Option key={`b-${b.id}`} value={b.id}>{b.nombre}</Select.Option>
                          )}
                          {/* <Select.Option value={11}>Banco de Venezuela S.A.C.A. Banco Universal</Select.Option> */}
                        </Select> : <div></div>}
                      </Form.Item>
                    </Col>}
                    {data.pagos && (data.pagos[field.name]?.metodoPago !== 'EFECTIVO' && data.pagos[field.name]?.metodoPago !== 'COBRAYA' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' ) && 
                    <Col xs={24} xl={8}>
                      <Form.Item label='Banco de Destino' name={[field.name, 'destino']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Debe indicar el banco' }]}>
                        {banks ? 
                        <Select placeholder='Banco de Destino'>
                          {
                            selectedInstitution?.cuentasBancarias?.filter(
                              (b) => mineralTax ? b.usoRamo?.includes('Minerales') : ( percentTax ? b.usoRamo?.includes('2x100') : ( bomberos ? b.usoRamo?.includes('Bomberos') : (peajes ? b.usoRamo?.includes('Peajes') : (b.usoRamo?.includes('Timbres') ||  (data.ramo === 516 && Number(b.id) === 8))) ))
                              ).filter((b) => b.metodoPago?.includes(data.pagos[field.name]?.metodoPago === 'PUNTO DE VENTA' ? 'PUNTO' : (data.pagos[field.name]?.metodoPago === 'COBRAYA' ? 'TRANSFERENCIA' : data.pagos[field.name]?.metodoPago))).map((b, i) =>
                              <Select.Option key={`b-${b.id}`} value={b.id}>{ b.numeroCuenta?.slice(b.numeroCuenta?.length - 4) + ' - ' + banks.find((bank) => bank.id === b.banco)?.nombre}</Select.Option>
                            )
                          }
                        </Select> : <div></div>}
                      </Form.Item>
                    </Col>}
                    {data.pagos && data.pagos[field.name]?.metodoPago === 'COBRAYA' && 
                    <Col xl={8} xs={24}>
                      <Form.Item label='Documento' rules={[{ required: true, message: 'Por favor ingresar el documento del titular'}]} name={[field.name, 'documento']}>
                        <Input addonBefore={docTypeSelect(field)} placeholder="Documento" maxLength={9} />
                      </Form.Item>
                    </Col>}          
                    {data.pagos && data.pagos[field.name]?.metodoPago === 'COBRAYA' && 
                    <Col xl={8} xs={24}>
                      <Form.Item label='Teléfono' rules={[{ required: true, message: 'Por favor ingrese el teléfono del titular' }]} name={[field.name, 'telefono']}>
                        <Input addonBefore={prefixSelect(field)} placeholder="Teléfono" maxLength={7} minLength={7}/>
                      </Form.Item>
                    </Col>} 
                    {data.pagos && (data.pagos[field.name]?.metodoPago !== 'EFECTIVO' && data.pagos[field.name]?.metodoPago !== 'COBRAYA' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' && data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' ) && 
                    <Col xs={24} xl={8}>
                      <Form.Item label='Numero de Referencia' name={[field.name, 'referencia']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.', whitespace:true}]}>
                        <Input placeholder='Referencia' maxLength={20} />
                      </Form.Item>
                    </Col>}
                    { data.pagos && data.pagos[field.name]?.metodoPago !== 'COBRAYA' &&
                    <Col xs={24} xl={8}>
                      <Form.Item label='Fecha de Pago' name={[field.name, 'fecha']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}>
                        <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"} disabledDate={(current) => current > moment().endOf('day')} />
                      </Form.Item>
                    </Col> }
                    <Col xs={24} xl={8}>
                      <Form.Item label='Monto' name={[field.name, 'costo']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese el monto del pago.' }]}>
                        <InputNumber max={data.pagos && data.pagos[field.name]?.metodoPago === 'CREDITO_FISCAL' ? maxCred : data.pagos && data.pagos[field.name]?.metodoPago === 'CREDITO_FISCAL_RETENCION' ? maxCredR : undefined} 
                          value={data.pagos[field.name]?.metodoPago === 'COBRAYA' ? (data.monto || 0) : data.pagos[field.name]?.costo }
                          disabled={data.pagos[field.name]?.metodoPago === 'COBRAYA'}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                          parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''} min={0} step={1} style={{ width: '100%' }} placeholder='Monto' />
                      </Form.Item>
                      {files && files.hasOwnProperty(`Comprobante${index+1}`) ? ( 
                       data.pagos[field.name]?.metodoPago !== 'COBRAYA' &&
                      <Col xs={24} xl={6} key={index}>
                      <Card
                        cover={<img alt='recaudo' ref={recaudosRef.current[index]} />}
                        bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <Typography.Text strong style={{ textAlign: 'center' }}>
                          {`Comprobante${index+1}`}
                        </Typography.Text>
                        <Button
                          type='link'
                          onClick={() => deleteFile(`Comprobante${index+1}`, index)}
                          style={{ textAlign: 'right', width: '100%', color: 'red' }}
                        >
                          Eliminar
                          <DeleteOutlined />
                        </Button>
                      </Card>
                    </Col>
                    ) : (
                      data.pagos[field.name]?.metodoPago !== 'COBRAYA' &&
                    <Col xs={24} xl={6}>
                      <input
                      hidden
                      ref={ref}
                      type='file'
                      onChange={(e) => handleFiles(e,`Comprobante${index+1}` ,index)}
                    />
                    <Button type='primary' style={{ marginTop: 10, marginBottom: 10}} onClick={() => ref.current?.click()}>
                      Cargar comprobante
                    </Button>  
                    </Col> 
                    )}                   
                  </Col>
                  {data.pagos && data.pagos[field.name]?.metodoPago === 'COBRAYA' && 
                    <Col xs={24} xl={8}>
                      {/* <Typography.Paragraph>{cobrayaText.find((el) => el.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === data.pagos[field.name]?.destino)?.banco)?.text || 'Seleccione Banco de Destino'}</Typography.Paragraph> */}
                      <Form.Item label='Clave C2P' name={[field.name, 'clave']} fieldKey={field.fieldKey} rules={[{ required: true, message: 'Por favor ingrese la clave.' }]}>
                        <Input placeholder='Ingrese clave C2P' maxLength={8} minLength={4} />
                      </Form.Item>
                    </Col>}
                  </Row>
                </Form.Item>
              </React.Fragment>
            )})}
            { (( data.pagos && data?.pagos[0]?.metodoPago !== 'COBRAYA') || (!data.pagos)) &&
            <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {((fields.length > 0 && !isLate) || (fields.length > 1 && isLate)) &&
              <Col>
                <Button type='danger' onClick={() => remove(fields.length - 1)}>Eliminar Transacción</Button>
              </Col>}
              <Col>
                <Button type='dashed' onClick={() => add()}>Agregar Transacción</Button>
              </Col>
            </Row>}
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ banks: state.bk.banks, auth: state.auth });

export default connect(mapStateToProps)(MultipleTransactions);

interface MultipleTransactionsProps {
  banks: States.Banks['banks']
  selectedInstitution?: Institucion
  form: FormInstance
  data: any
  maxCred?: number
  maxCredR?: number
  selectedReqType?: string
  auth: States.Auth
  files?: any
  setFiles?: any
  percentTax?: boolean
  mineralTax?: boolean
  credit?: any
  bomberos?: boolean
  peajes?: boolean
  isLate?: boolean
}