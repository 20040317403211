import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import {
  Card,
  Form,
  Tabs,
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
  Spin,
  Divider,
  Typography,
  Table,
  InputNumber,
  Descriptions,
  Checkbox,
  Modal,
  Collapse,
  Alert,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import {
  States,
  State,
  ActividadEconomica,
  ServicioMunicipal,
  InmuebleUrbano,
  Publicidad,
  Liquidacion,
  Request,
  Fecha,
} from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';
import { LoadingOutlined, DeleteOutlined, CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { remove, cloneDeep, round } from 'lodash';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { addRequest } from '../../redux/actions/requests';
import '../../assets/css/components/DeclareTaxes.css';
import { setTax } from '../../redux/actions/taxes';
import UserSelect from '../Forms/components/UserSelect';
import { Utils } from '../../utils/validators';
import moment from 'moment';
import Petro from '../Icons/Petro';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import { getCurrency } from '../../utils/currency';
const server = process.env.REACT_APP_SERVER_URL;

const DeclareAnyTaxes: React.FC<DeclareTaxesProps> = ({ thm, auth, banks, institutions, addRequest }) => {
  const { Option } = Select;
  const [nacionalidadRif, setNacionalidadRif] = useState('J');
  const [nacionalidad, setNacionalidad] = useState('V');
  const [montoS, setMontoS] = useState(0);
  const [montoSD, setMontoSD] = useState(0);
  const [active, setActive] = useState<'JURIDICO' | 'NATURAL'>('JURIDICO');
  const [loading, setLoading] = useState(false);
  const [taxes, setTaxes] = useState<Taxes | null>(null);
  const [data, setData] = useState<any>({});
  const [PP, setPP] = useState<{ [f: string]: any[] }>({});
  const [files, setFiles] = useState<{ [P: string]: File } | null>(null);
  const [filesPay, setFilesPay] = useState<{ [P: string]: File } | null>(null);
  const [article, setArticle] = useState<{ [f: string]: string } | undefined>(undefined);
  const [subarticle, setSubarticle] = useState<{ [f: string]: string } | undefined>(undefined);
  const [quantity, setQuantity] = useState<{ [f: string]: number } | undefined>(undefined);
  const [credit, setCredit] = useState<any[any]>([]);
  const [includeIU, setIncludeIU] = useState<boolean>(false);
  const [includePP, setIncludePP] = useState<boolean>(false);
  const [includeAE, setIncludeAE] = useState<boolean>(true);
  const [includeSM, setIncludeSM] = useState<boolean>(false);
  const [isLate, setIsLate] = useState<boolean>(false);
  const [esVigente, setEsVigente] = useState(true);
  const [creating, setCreating] = useState(false);
  const [esAgenteSENIAT, setEsAgenteSENIAT] = useState(false);
  const [fetchingIU, setFetchingIU] = useState(false);
  const [processId, setProcessId] = useState<number>(0);

  const selectedInstitution = institutions?.find((i) => i.id === 9);
  const petro = useSelector((state: State) => state.coin.petro);
  const allowedPayment = [25, 22];
  const solvencia = +((taxes?.AE?.[0].costoSolvencia || 0) / petro).toFixed(2);

  const { width } = useWindowDimensions();
  const history = useHistory();
  const [form] = Form.useForm();
  const soportesRef = useRef<RefObject<HTMLImageElement>[]>([]);

  const mesesNumerico = {
    enero: 0,
    febrero: 1,
    marzo: 2,
    abril: 3,
    mayo: 4,
    junio: 5,
    julio: 6,
    agosto: 7,
    septiembre: 8,
    octubre: 9,
    noviembre: 10,
    diciembre: 11,
  };

  const handleFiles = (e, name: string, i: number) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      const blob = (file as File).slice(0, (file as File).size, file.type);
      setFiles({
        ...files,
        [name]: new File([blob], `${name}.${file.name.split('.')[file.name.split('.').length - 1]}`, { type: file.type }),
      });
      fr.readAsDataURL(file);
      fr.onload = (e) => {
        if (file.type.includes('image')) soportesRef.current[i]?.current?.setAttribute('src', e.target?.result?.toString() || '');
        else if (file.type.includes('excel')) {
          soportesRef.current[i]?.current?.setAttribute('src', require('../../assets/images/excel.png'));
          soportesRef.current[i]?.current?.style.setProperty('width', '80px');
          soportesRef.current[i]?.current?.style.setProperty('height', '80px');
        } else if (file.type.includes('pdf')) {
          soportesRef.current[i]?.current?.setAttribute('src', require('../../assets/images/pdf.png'));
          soportesRef.current[i]?.current?.style.setProperty('width', '80px');
          soportesRef.current[i]?.current?.style.setProperty('height', '80px');
        } else {
          soportesRef.current[i]?.current?.setAttribute('src', require('../../assets/images/file.png'));
          soportesRef.current[i]?.current?.style.setProperty('width', '80px');
          soportesRef.current[i]?.current?.style.setProperty('height', '80px');
        }
      };
    }
  };

  const deleteFile = (name: string, i: number) => {
    const _files = Object.assign({}, files);
    delete _files[name];
    setFiles(_files);
    soportesRef.current[i] = createRef();
  };

  const handlePreview = async (file) => {
    if (!file.url) {
      const win = window.open(file, '_blank');
      win?.focus();
    }
  };

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  useEffect(() => {
    if (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) === 1) {
      handleExternal();
    }
  }, []);

  useEffect(() => {
    soportesRef.current =
      taxes?.AE && taxes?.AE?.length > 0 ? taxes?.AE[getLongerAE()]?.deuda?.map(() => createRef<HTMLImageElement>()) : [];
  }, [taxes]);

  const fetchCredit = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/settlements/contributor/credit/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setCredit(response.data.credit);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las compensaciones');
    } finally {
      setLoading(false);
    }
  };

  const reverseOperation = async () => {
    if(processId){
      setLoading(true);
      try {
        const response = await axios.delete(`${server}/settlements/incomplete/${processId}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.info('Operación reversada.');
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al reversar operación.');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (taxes) {
      fetchCredit(taxes?.contribuyente);
      if(taxes.AE){
        const today = moment();
        const taxDate = moment().year(taxes?.AE[0]?.deuda[0]?.year).month(mesesNumerico[taxes?.AE[0]?.deuda[0]?.month]).date(1);
        setIsLate(today.diff(taxDate,'M') > 1)
      }
    }
  }, [taxes]);

  useEffect(() => {
    console.log(`monto s ${montoS}`)
    console.log(`monto sd ${montoSD}`)
  }, [montoS, montoSD]);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) {
      if (auth.user?.contribuyente) {
        setActive(auth.user.contribuyente.tipoContribuyente);
        setNacionalidad(auth.user.contribuyente.tipoDocumento);
        setNacionalidadRif(auth.user.contribuyente.tipoDocumento);
      } else {
        message.error('Este usuario no posee un contribuyente asociado para la declaración de impuestos.');
        history.push('/dashboard');
      }
    }
    // eslint-disable-next-line
  }, [auth.user]);

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 4) {
      form.setFieldsValue({
        documento: null,
        rim: null,
      });
    }
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    form.setFieldsValue({
      PP: {
        subarticulo: undefined,
      },
    });
    // eslint-disable-next-line
  }, [data.PP?.articulo]);

  useEffect(() => {
    if (taxes?.esContribuyenteTop) {
      setIncludeAE(true);
      // setIncludeSM(true);
    }
    if (taxes?.esAgenteRetencion) {
      setIncludeAE(true);
      // setIncludeSM(true);
      // setIncludeIU(true);
    }
  }, [taxes]);

  useEffect(() => {
    const includingAE = getAllAESubtotal() === -1 && !!taxes?.AE;
    if (includeIU) {
      if (includingAE) {
        setIncludeIU(false);
      } else {
        fetchIU();
      }
    }
    // eslint-disable-next-line
  }, [includeIU, data]);

  const fetchIU = async () => {
    setFetchingIU(true);
    try {
      setTaxes((_taxes) => (_taxes ? { ..._taxes, IU: [] } : null));
      const deuda = taxes?.AE?.[0]?.deuda[taxes?.AE?.[0]?.deuda.length - 1];
      const documento = auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.documento : form.getFieldValue('documento');
      const monto = +getAESubtotal(deuda?.month, deuda?.year);
      const declaredAE = !isNaN(monto);
      const response = await axios.get(
        `${server}/settlements/iu/declaration?doc=${documento}${data.rim ? `&ref=${data.rim}` : ''}&pref=${
          active === 'JURIDICO' ? nacionalidadRif : nacionalidad
        }${declaredAE ? `&amount=${monto}` : ''}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setTaxes((_taxes) => (_taxes ? { ..._taxes, IU: response.data.IU } : null));
    } catch (e) {
      message.error('Error al consultar impuestos de inmuebles urbanos.');
    } finally {
      setFetchingIU(false);
    }
  };

  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const calculateIU = () => {
    const calc: {
      desglose: { inmueble: number; monto: number; descuento: number }[];
      fechaCancelada: { month: string; year: number };
      monto: number;
      ramo: 'IU';
    }[] = [];
    taxes?.IU?.forEach((t) => {
      t.deuda.forEach((d) => {
        const index = calc.findIndex((c) => c.fechaCancelada.month === d.month && c.fechaCancelada.year === d.year);
        if (index === -1) {
          calc.push({
            desglose: [
              { inmueble: t.id, monto: !d.exonerado ? d.impuestoInmueble * (1 - d.descuento) : 0, descuento: d.descuento },
            ],
            fechaCancelada: { month: d.month, year: d.year },
            monto: !d.exonerado ? +((d.impuestoInmueble * (1 - d.descuento)) / (taxes.petro || 1)).toFixed(8) : 0,
            ramo: 'IU',
          });
        } else {
          calc[index].desglose = [
            ...calc[index].desglose,
            { inmueble: t.id, monto: !d.exonerado ? d.impuestoInmueble * (1 - d.descuento) : 0, descuento: d.descuento },
          ];
          calc[index].monto =
            calc[index].monto + (!d.exonerado ? +((d.impuestoInmueble * (1 - d.descuento)) / (taxes.petro || 1)).toFixed(8) : 0);
        }
      });
    });
    return calc;
  };

  const calculateSM = () => {
    const calc: {
      desglose: { inmueble: number; montoAseo: number; montoGas: number; descuento: number }[];
      fechaCancelada: { month: string; year: number };
      monto: number;
      ramo: 'SM';
    }[] = [];
    taxes?.SM?.forEach((t) => {
      t.deuda.forEach((d) => {
        const index = calc.findIndex((c) => c.fechaCancelada.month === d.month && c.fechaCancelada.year === d.year);
        if (index === -1) {
          calc.push({
            desglose: [
              {
                inmueble: t.id,
                montoAseo: !d.exonerado ? +(+t.tarifaAseo * (1 - d.descuento)) : 0,
                montoGas: !d.exonerado ? +(+t.tarifaGas * (1 - d.descuento)) : 0,
                descuento: d.descuento,
              },
            ],
            fechaCancelada: { month: d.month, year: d.year },
            monto: !d.exonerado ? +(+t.tarifaGas * (1 - d.descuento)) + +(+t.tarifaAseo * (1 - d.descuento)).toFixed(8) : 0,
            ramo: 'SM',
          });
        } else {
          calc[index].desglose = [
            ...calc[index].desglose,
            {
              inmueble: t.id,
              montoAseo: !d.exonerado ? t.tarifaAseo * (1 - d.descuento) : 0,
              montoGas: !d.exonerado ? t.tarifaGas * (1 - d.descuento) : 0,
              descuento: d.descuento,
            },
          ];
          calc[index].monto =
            calc[index].monto + (!d.exonerado ? +(+t.tarifaGas * (1 - d.descuento)) + +(+t.tarifaAseo * (1 - d.descuento)) : 0);
        }
      });
    });
    return calc;
  };

  const handleSubmit = async () => {
    const documento = auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.documento : form.getFieldValue('documento');
    const isValid = !!documento;
    if (isValid) {
      try {
        setLoading(true);
        const response = await axios.get<{ impuesto: Taxes }>(
          `${server}/settlements?doc=${documento}${`&ref=${documento}`}&pref=${
            active === 'JURIDICO' ? nacionalidadRif : nacionalidad
          }&contrib=${active}`,
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        // if (response.status === 202)
        // history.push('/dashboard/impuestos/enlazar', { datosEnlace: (response.data as any).datosEnlace[0] });
        setTaxes(response.data.impuesto);
      } catch (e) {
        message.error(e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor');
      } finally {
        setLoading(false);
      }
    } else {
      message.error('Debe ingresar los datos requeridos para la consulta de impuestos.');
    }
  };

  const handleExternal = async () => {
    const documento = auth?.user?.contribuyente?.documento;
    try {
      setLoading(true);
      const response = await axios.get<{ impuesto: Taxes }>(
        `${server}/settlements?doc=${documento}${`&ref=${auth?.user?.contribuyente?.documento}`}&pref=${
          auth?.user?.contribuyente?.tipoDocumento
        }&contrib=${auth?.user?.contribuyente?.tipoContribuyente}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      if (response.status === 202)
        history.push('/dashboard/impuestos/enlazar', { datosEnlace: (response.data as any).datosEnlace[0] });
      setTaxes(response.data.impuesto);
    } catch (e) {
      message.error(e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor');
    } finally {
      setLoading(false);
    }
  };

  const isMinTributable = (year, month, id) => {
    const AE = cloneDeep(taxes?.AE || []);
    const isExo = AE.find((ae) => ae.id === id)?.deuda?.find((d) => d.month === month && d.year === year)?.exonerado;
    if (isExo) return false;
    if (data && data[year] && data[year][month]) {
      const lowerFee = AE.sort((a, b) => {
        if (a.alicuota > b.alicuota) return 1;
        if (b.alicuota > a.alicuota) return -1;
        return 0;
      }).find((ae) => data[year][month][ae.id] * ae.alicuota <= ae.minimoTributable);
      return lowerFee?.id === id;
    }
    return false;
  };

  const declareMono = async () => {
    // const hasAllMandatoryTakings = files && taxes && taxes?.AE
    // ? !taxes?.AE[getLongerAE()]?.deuda?.find((d) => !files.hasOwnProperty(`${d.month+d.year}`)) || false : false;
    const checkF = (i) => {
      return filesPay ? filesPay.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let values = await form.validateFields();
    let notP: Array<any> = [];
    if (values.pagos && values.pagos?.length > 0) {
      values.pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = values.pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    
    const hasAllMandatoryTakingsPay =
      values.monto === 0
        ? true
        : auth?.user?.tipoUsuario === 4
        ? esVigente
          ? true
          : notP && notP?.length > 0
          ? !notP.some((p, i) => p.check === false)
          : true
        : true;
    let ingresadoCompensacion = values.compensaciones
      ? credit
          ?.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          ?.reduce((prev, current) => prev + current.disponible, 0)
      : 0;
    let ingresado = esVigente || values.monto === 0 ? 0 : values.pagos?.reduce((prev, current) => prev + current.costo, 0);
    let montoPorPagar = getAEGrandTotal();
    if (!esVigente && montoPorPagar > ingresado + Number(ingresadoCompensacion)) {
      return message.error('El monto de los pagos debe ser igual al monto de la declaración');
    }
    if (!hasAllMandatoryTakingsPay) {
      return message.error('Debe ingresar un comprobante por cada pago');
    }
    const hasAllMandatoryTakings = auth?.user?.tipoUsuario === 4 ? files && Object.keys(files)?.length >= 1 : true;
    if (!hasAllMandatoryTakings) return message.error('Debe ingresar el soporte');
    const impuestos: TaxDeclaration['impuestos'] = [];
    if (taxes?.MONO) {
      const { montoAE, montoIU, montoPP, montoSM, montoSAE, aforos } = taxes.MONO;
      taxes?.MONO?.deuda.forEach((m, i) => {
        if (i === 0) {
          impuestos.push({
            ramo: 'AE',
            fechaCancelada: {
              month: moment().locale('es').month(m.month).subtract(1, 'month').format('MMMM'),
              year: +moment().locale('es').month(m.month).subtract(1, 'month').format('YYYY'),
            },
            monto: +Number(montoAE + montoSAE).toFixed(8),
            desglose: aforos.map((a) => ({ aforo: a, montoDeclarado: 0, montoCobrado: 0 })),
          });
          impuestos.push({
            ramo: 'IU',
            fechaCancelada: {
              month: m.month,
              year: m.year,
            },
            monto: +Number(montoIU).toFixed(8),
            desglose: undefined,
          });
          impuestos.push({
            ramo: 'SM',
            fechaCancelada: {
              month: m.month,
              year: m.year,
            },
            monto: +Number(montoSM / 1.16).toFixed(8),
            desglose: [{ inmueble: 0, montoAseo: (montoSM / 1.16 / 2) * petro, montoGas: (montoSM / 1.16 / 2) * petro }],
          });
          impuestos.push({
            ramo: 'PP',
            fechaCancelada: {
              month: m.month,
              year: m.year,
            },
            monto: +Number(montoPP).toFixed(8),
            desglose: undefined,
          });
        }
      });
      if (!esVigente) {
        values.pagos
          .filter((p) => p)
          .forEach((p) => {
            Object.keys(p).forEach((k) => {
              if (moment.isMoment(p[k])) {
                p[k] = p[k].toISOString();
              }
            });
          });
      }
      if (values.pagos) {
        for (let i = 0; i < values.pagos.length; i++) {
          values.pagos[i] = { ...values.pagos[i], id_usuario: auth?.user?.id };
        }
      }
      values.esVigente = esVigente;
      const montoTotal = impuestos.map((i) => i.monto)?.reduce((c, v) => c + v, 0);
      const procedimiento: TaxDeclaration = {
        documento: form.getFieldValue('documento') || auth.user?.contribuyente?.documento,
        rim: form.getFieldValue('rim') || auth.user?.contribuyente?.documento || data.rim || data.documento,
        tipoDocumento: active === 'JURIDICO' ? nacionalidadRif : nacionalidad,
        impuestos,
        totalPagoImpuestos: montoTotal || 0,
        contribuyente: taxes?.contribuyente,
        usuario: form.getFieldValue('usuario'),
        esMonotributo: true,
        esAgenteSENIAT,
        credit:
          credit && credit?.length > 0
            ? credit.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
            : null,
        ...values,
      };
      setCreating(true);
      try {
        const response = await axios.post(
          `${server}/settlements/init`,
          { procedimiento },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success('Declaración iniciada exitosamente.');
        addRequest({ ...response.data.solicitud, creditoFiscal: taxes?.creditoFiscal });
        setProcessId(response.data?.solicitud?.id);
        response.data.solicitud.liquidaciones.forEach((item) => {
          const data: Liquidacion = {
            id: item.id,
            fecha: item.fecha,
            ramo: item.ramo,
            monto: item.monto || 0,
            montoPetro: item.montoPetro,
            aprobado: response.data.solicitud.aprobado,
            pagado: response.data.solicitud.pagado,
            estado: response.data.solicitud.estado,
          };
          setTax(data);
        });

        const fd = new FormData();
        let fileCount = 0;
        if (files)
          Object.keys(files).forEach((k) => {
            fd.append('recaudos', files[k]);
            fileCount++;
          });

        if (fileCount > 0) {
          const resp = await axios.post(`${server}/uploads/ae/support/${response.data?.solicitud?.id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (resp.status === 200){
            message.success('Soportes de declaración guardados.');
            const fdpay = new FormData();
            let fileCountPay = 0;
            if (filesPay)
              Object.keys(filesPay).forEach((k) => {
                fdpay.append('recaudos', filesPay[k]);
                fileCountPay++;
              });
            if (fileCountPay > 0) {
              const resppay = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fdpay, {
                headers: { Authorization: `Bearer ${auth.token}` },
              });
              if(resppay.status === 200) {
                message.success('Trámite creado exitosamente');
                setCreating(false);
                !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
              }
            }
            else{
              message.success('Trámite creado exitosamente');
              setCreating(false);
              !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
            }
          } 
        }
        else{
          const fdpay = new FormData();
          let fileCountPay = 0;
          if (filesPay)
            Object.keys(filesPay).forEach((k) => {
              fdpay.append('recaudos', filesPay[k]);
              fileCountPay++;
            });
          if (fileCountPay > 0) {
            const resppay = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fdpay, {
              headers: { Authorization: `Bearer ${auth.token}` },
            });
            if(resppay.status === 200) {
              message.success('Trámite creado exitosamente');
              setCreating(false);
              !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
            }
          }
          else{
            message.success('Trámite creado exitosamente');
            setCreating(false);
            !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
          }
        }
      } catch (e) {
        message.error(e?.response?.data?.message || 'Error en el proceso de declaración.');
        reverseOperation();
      } finally {
        setCreating(false);
      }
    }
  };

  const declare = async () => {
    // const hasAllMandatoryTakings = files && taxes && taxes?.AE
    // ? !taxes?.AE[getLongerAE()]?.deuda?.find((d) => !files.hasOwnProperty(`${d.month+d.year}`)) || false : false;

    const checkF = (i) => {
      return filesPay ? filesPay.hasOwnProperty(`Comprobante${i + 1}`) : false;
    };
    let values = await form.validateFields();
    let notP: Array<any> = [];
    if (values.pagos && values.pagos?.length > 0) {
      values.pagos.map((p, i) => {
        p.i = i;
        p.idInstitucionBanco = p.destino;
        p.destino = banks?.find(
          (b) => b.id === selectedInstitution?.cuentasBancarias?.find((c) => c.id === p.destino)?.banco
        )?.id;
        return p;
      });
      let filterP = values.pagos.filter((p) => p.metodoPago !== 'PUNTO DE VENTA');
      if (filterP && filterP.length > 0) {
        filterP.map((p, i) => {
          p.check = checkF(p.i);
          notP.push(p);
        });
      }
    }
    const hasAllMandatoryTakingsPay =
      values.monto === 0
        ? true
        : auth?.user?.tipoUsuario === 4
        ? esVigente
          ? true
          : notP && notP?.length > 0
          ? !notP.some((p, i) => p.check === false)
          : true
        : true;
    let ingresadoCompensacion = values.compensaciones
      ? credit
          ?.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          ?.reduce((prev, current) => prev + current.disponible, 0)
      : 0;
    let ingresado = esVigente || values.monto === 0 ? 0 : values.pagos?.reduce((prev, current) => prev + current.costo, 0);
    let montoPorPagar = getAEGrandTotal();
    if (!esVigente && montoPorPagar > ingresado + Number(ingresadoCompensacion)) {
      return message.error('El monto de los pagos debe ser igual al monto de la declaración');
    }
    if (!hasAllMandatoryTakingsPay && auth?.user?.tipoUsuario === 4) {
      return message.error('Debe ingresar un comprobante por cada pago');
    }
    const hasAllMandatoryTakings = files && Object.keys(files)?.length >= 1;
    if (!hasAllMandatoryTakings && auth?.user?.tipoUsuario === 4) return message.error('Debe ingresar el soporte');
    if (fetchingIU)
      return message.warning(
        'Debe calcularse los impuestos a pagar por Inmuebles Urbanos para hacer la declaración correspondiente.'
      );
    if (includeAE && getAllAESubtotal() === -1 && taxes?.AE)
      return message.error('Debe ingresar la declaración correspondiente a Actividades Economicas');
    const impuestos: TaxDeclaration['impuestos'] = [];
    if (includeAE && taxes?.AE && taxes?.AE.length > 0) {
      taxes?.AE[getLongerAE()].deuda.forEach((ae, i) => {
        if (i === 0) {
          impuestos.push({
            ramo: 'AE',
            fechaCancelada: {
              month: ae.month,
              year: ae.year,
            },
            monto: taxes.AE
              ? +(
                  parseFloat(getAESubtotal(ae.month, ae.year) || '0') /*+ taxes.AE[0].costoSolvencia*/ / (taxes.petro || 1)
                ).toFixed(8)
              : 0,
            desglose: taxes.AE?.filter((d) => d.deuda.some((de) => de.month === ae.month && de.year === ae.year)).map((i) =>
              ae.year === 2023 && ae.month === 'noviembre'
                ? {
                    aforo: i.id,
                    montoDeclarado:
                      Number(data[ae.year][ae.month][i.id][0].toFixed(2)) + Number(data[ae.year][ae.month][i.id][1].toFixed(2)),
                    montoCobrado:
                      parseFloat((data[ae.year][ae.month][i.id][0] * 0.02).toFixed(2)) +
                      parseFloat((data[ae.year][ae.month][i.id][1] * i.alicuota).toFixed(2)),
                    montoDeclaradoDosPor: data[ae.year][ae.month][i.id][0].toFixed(2),
                    montoDeclaradoPorMil: data[ae.year][ae.month][i.id][1].toFixed(2),
                    montoCobradoDosPor: parseFloat((data[ae.year][ae.month][i.id][0] * 0.02).toFixed(2)),
                    montoCobradoPorMil: parseFloat((data[ae.year][ae.month][i.id][1] * i.alicuota).toFixed(2)),
                  }
                : {
                    aforo: i.id,
                    montoDeclarado: data[ae.year][ae.month][i.id].toFixed(2),
                    montoCobrado: parseFloat((data[ae.year][ae.month][i.id] * i.alicuota).toFixed(2)),
                  }
            ),
          });
        }
      });
    }
    if (includeAE && taxes && taxes.AE && taxes.montoAcarreado.AE.monto > 0) {
      impuestos.push({
        ramo: 'AE',
        fechaCancelada: {
          month: taxes.montoAcarreado.AE.fecha.month,
          year: taxes.montoAcarreado.AE.fecha.year,
        },
        monto: +(taxes.montoAcarreado.AE.monto /* + taxes.AE[0].costoSolvencia*/ / taxes.petro).toFixed(8),
        desglose: null,
      });
    }
    if (includeSM && taxes?.SM && taxes?.SM.length > 0) {
      impuestos.push(...calculateSM());
    }
    if (includeSM && taxes && taxes.montoAcarreado.SM.monto > 0) {
      impuestos.push({
        ramo: 'SM',
        fechaCancelada: {
          month: taxes.montoAcarreado.SM.fecha.month,
          year: taxes.montoAcarreado.SM.fecha.year,
        },
        monto: taxes.montoAcarreado.SM.monto,
        desglose: null,
      });
    }
    if (includeIU && taxes?.IU && taxes.IU.length > 0) {
      impuestos.push(...calculateIU());
    }
    if (includeIU && taxes && taxes.montoAcarreado.IU.monto > 0) {
      impuestos.push({
        ramo: 'IU',
        fechaCancelada: {
          month: taxes.montoAcarreado.IU.fecha.month,
          year: taxes.montoAcarreado.IU.fecha.year,
        },
        monto: taxes.montoAcarreado.IU.monto,
        desglose: null,
      });
    }
    if (includePP && taxes && taxes.PP) {
      taxes.PP.deuda.forEach((pp) => {
        impuestos.push({
          ramo: 'PP',
          fechaCancelada: {
            month: pp.month,
            year: pp.year,
          },
          monto: +(getPPSubtotal(pp.month + pp.year) / (taxes.petro || 1)).toFixed(8),
          desglose: PP[pp.month + pp.year]
            ? PP[pp.month + pp.year].map((p) => ({
                subarticulo: taxes.PP
                  ? taxes.PP.articulos
                      .find((art) => {
                        return !!art.subarticulos.find((subart) => subart.nombreSubarticulo === p.subarticulo)?.id;
                      })
                      ?.subarticulos.find((subart) => subart.nombreSubarticulo === p.subarticulo)?.id
                  : 0,
                cantidad: p.cantidad,
                monto: getPPItemCost(p.date, p.articulo, p.subarticulo, p.cantidad).toFixed(2),
              }))
            : [],
        });
      });
    }
    if (includePP && taxes && taxes.montoAcarreado.PP.monto > 0) {
      impuestos.push({
        ramo: 'PP',
        fechaCancelada: {
          month: taxes.montoAcarreado.PP.fecha.month,
          year: taxes.montoAcarreado.PP.fecha.year,
        },
        monto: taxes.montoAcarreado.PP.monto,
        desglose: null,
      });
    }
    const montoTotal = Math.round(
      impuestos.map((i) => i.monto)?.reduce((c, v) => parseFloat(c.toFixed(2)) + parseFloat(v.toFixed(2)))
    );
    if (!esVigente && values.pagos) {
      values.pagos
        .filter((p) => p)
        .forEach((p) => {
          Object.keys(p).forEach((k) => {
            if (moment.isMoment(p[k])) {
              p[k] = p[k].toISOString();
            }
          });
        });
    }
    if (values.pagos) {
      for (let i = 0; i < values.pagos.length; i++) {
        values.pagos[i] = { ...values.pagos[i], id_usuario: auth?.user?.id };
      }
    }
    values.esVigente = esVigente;
    const procedimiento: TaxDeclaration = {
      documento: form.getFieldValue('documento') || auth.user?.contribuyente?.documento,
      rim: form.getFieldValue('rim') || auth.user?.contribuyente?.documento || data.rim || data.documento,
      tipoDocumento: active === 'JURIDICO' ? nacionalidadRif : nacionalidad,
      impuestos,
      totalPagoImpuestos: montoTotal || 0,
      contribuyente: taxes?.contribuyente,
      usuario: form.getFieldValue('usuario'),
      esAgenteRetencion: taxes?.esAgenteRetencion,
      esAgenteSENIAT,
      esMonotributo: false,
      credit:
        credit && credit?.length > 0
          ? credit.filter((c) => values?.compensaciones?.find((com) => com === c.id_credito_fiscal))
          : null,
      ...values,
    };
    setCreating(true);
    try {
      const response = await axios.post(
        `${server}/settlements/init`,
        { procedimiento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Declaración iniciada exitosamente.');
      addRequest({ ...response.data.solicitud, creditoFiscal: taxes?.creditoFiscal });
      setProcessId(response.data?.solicitud?.id);
      response.data.solicitud.liquidaciones.forEach((item) => {
        const data: Liquidacion = {
          id: item.id,
          fecha: item.fecha,
          ramo: item.ramo,
          monto: item.monto || 0,
          montoPetro: item.montoPetro,
          aprobado: response.data.solicitud.aprobado,
          pagado: response.data.solicitud.pagado,
          estado: response.data.solicitud.estado,
        };
        setTax(data);
      });

      const fd = new FormData();
      let fileCount = 0;
      if (files)
        Object.keys(files).forEach((k) => {
          fd.append('recaudos', files[k]);
          fileCount++;
        });

      if (fileCount > 0) {
        const resp = await axios.post(`${server}/uploads/ae/support/${response.data?.solicitud?.id}`, fd, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        if (resp.status === 200){
          message.success('Soportes de declaración guardados.');
          const fdpay = new FormData();
          let fileCountPay = 0;
          if (filesPay)
            Object.keys(filesPay).forEach((k) => {
              fdpay.append('recaudos', filesPay[k]);
              fileCountPay++;
            });
          if (fileCountPay > 0) {
            const resppay = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fdpay, {
              headers: { Authorization: `Bearer ${auth.token}` },
            });
            if(resppay.status === 200) {
              message.success('Trámite creado exitosamente');
              setCreating(false);
              !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
            }
          }
          else{
            message.success('Trámite creado exitosamente');
            setCreating(false);
            !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
          }
        }
        else{
          const fdpay = new FormData();
          let fileCountPay = 0;
          if (filesPay)
            Object.keys(filesPay).forEach((k) => {
              fdpay.append('recaudos', filesPay[k]);
              fileCountPay++;
            });
          if (fileCountPay > 0) {
            const resppay = await axios.post(`${server}/uploads/special/support/${response.data?.solicitud?.id}`, fdpay, {
              headers: { Authorization: `Bearer ${auth.token}` },
            });
            if(resppay.status === 200) {
              message.success('Trámite creado exitosamente');
              setCreating(false);
              !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
            }
          }
          else{
            message.success('Trámite creado exitosamente');
            setCreating(false);
            !allowedPayment.find((id) => auth.user?.institucion?.cargo?.id === id) ? setTaxes(null) : history.push(`/dashboard`);
          }
        } 
      }
    } catch (e) {
      message.error(e?.response?.data?.message || 'Error en el servidor');
      reverseOperation();
    } finally {
      setCreating(false);
    }
  };

  const rifAddon = (
    <Select value={nacionalidadRif} onChange={(e) => setNacionalidadRif(e)}>
      <Select.Option value='J'>J</Select.Option>
      {/* <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option> */}
      <Select.Option value='G'>G</Select.Option>
    </Select>
  );

  const cedulaAddon = (
    <Select value={nacionalidad} onChange={(e) => setNacionalidad(e)}>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='V'>V</Select.Option>
    </Select>
  );

  const AEcolumns: ColumnsType<ActividadEconomica> = [
    {
      title: 'Aforo',
      dataIndex: 'nombreActividad',
      key: 'nombreActividad',
    },
    {
      title: 'Alicuota',
      dataIndex: 'date',
      key: 'alicuota',
      render: (date, record) => {
        const doubleMonth = date.year === 2023 && date.month === 'noviembre';
        const unoPorCienDate = moment().year(2022).month(2).date(31);
        const dosPorCienDate = moment().year(2023).month(10).date(9);
        const taxDate = moment().year(date.year).month(mesesNumerico[date.month]).date(1);
        let taxRate = record.alicuota * 100;
        if (doubleMonth) {
          if (record.key === 0) {
            taxRate = 0.02 * 100;
          }
        } else {
          if (taxDate.isBefore(unoPorCienDate)) {
            taxRate = 0.01 * 100;
          } else if (taxDate.isBefore(dosPorCienDate)) {
            taxRate = 0.02 * 100;
          }
        }
        return <span>{parseFloat(taxRate.toPrecision(15)).toFixed(2)}%</span>;
      },
    },
    // {
    //   title: 'Mínimo Tributable',
    //   dataIndex: 'minimoTributable',
    //   // render: (mt, record) => (!record.date?.exo ? <span>{`Bs. ${formatCurrency(mt)}`}</span> : <b>EXONERADO</b>), METODO VIEJO DE MARACAIBO
    //   render: (mt, record) => <b>NO APLICA</b>,
    // },
    {
      title: 'Total Monto Gravable  (Bs.)',
      dataIndex: 'date',
      render: (date, record) => {
        const doubleMonth = date.year === 2023 && date.month === 'noviembre';
        return (
          <Form.Item
            name={doubleMonth ? [date.year, date.month, record.id, record.key] : [date.year, date.month, record.id]}
            style={{ margin: 0, width: '100%' }}
            label={doubleMonth ? (record.key === 0 ? 'Del 1 al 8 de noviembre' : 'A partir del 9 de noviembre') : ''}
          >
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              min={0}
              step={1}
              style={{ width: '100%' }}
              placeholder='Monto'
              onChange={(v) => (doubleMonth ? (record.key === 0 ? setMontoS(v || 0) : setMontoSD(v || 0)) : setMontoS(v || 0))}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Impuesto',
      dataIndex: 'date',
      render: (date, record) => {
        const doubleMonth = date.year === 2023 && date.month === 'noviembre';
        const exists = doubleMonth
          ? data[date.year] && data[date.year][date.month] && data[date.year][date.month][record.id][record.key]
          : data[date.year] && data[date.year][date.month] && data[date.year][date.month][record.id];
        const unoPorCienDate = moment().year(2022).month(2).date(31);
        const dosPorCienDate = moment().year(2023).month(10).date(9);
        const taxDate = moment().year(date.year).month(mesesNumerico[date.month]).date(1);
        let taxRate = record.alicuota;
        if (doubleMonth) {
          if (record.key === 0) {
            taxRate = 0.02;
          }
        } else {
          if (taxDate.isBefore(unoPorCienDate)) {
            taxRate = 0.01;
          } else if (taxDate.isBefore(dosPorCienDate)) {
            taxRate = 0.02;
          }
        }
        return (
          <span>
            {exists
              ? `Bs. ${parseFloat(
                  (
                    (doubleMonth ? data[date.year][date.month][record.id][record.key] : data[date.year][date.month][record.id]) *
                    taxRate
                  ).toFixed(2)
                )}`
              : exists === 0
              ? `Bs. 0`
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: getCurrency(),
      dataIndex: 'date',
      render: (date, record) => {
        const doubleMonth = date.year === 2023 && date.month === 'noviembre';
        const exists = doubleMonth
          ? data[date.year] &&
            data[date.year][date.month] &&
            data[date.year][date.month][record.id] &&
            data[date.year][date.month][record.id][record.key]
          : data[date.year] && data[date.year][date.month] && data[date.year][date.month][record.id];
        const unoPorCienDate = moment().year(2022).month(2).date(31);
        const dosPorCienDate = moment().year(2023).month(10).date(9);
        const taxDate = moment().year(date.year).month(mesesNumerico[date.month]).date(1);
        let taxRate = record.alicuota;
        if (doubleMonth) {
          if (record.key === 0) {
            taxRate = 0.02;
          }
        } else {
          if (taxDate.isBefore(unoPorCienDate)) {
            taxRate = 0.01;
          } else if (taxDate.isBefore(dosPorCienDate)) {
            taxRate = 0.02;
          }
        }
        return (
          <span>
            {exists
              ? `${Number(
                  parseFloat(
                    (
                      ((doubleMonth
                        ? data[date.year][date.month][record.id][record.key]
                        : data[date.year][date.month][record.id]) *
                        taxRate) /
                      (taxes?.petro || 1)
                    ).toFixed(8)
                  )
                ).toFixed(8)}`
              : exists === 0
              ? `0`
              : 'N/A'}
            <Petro />
          </span>
        );
      },
    },
  ];

  const SMcolumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      title: 'Tarifa Aseo',
      dataIndex: 'tarifaAseo',
      render: (ta, record) =>
        record.deuda.exo ? <b>EXONERADO</b> : <span>Bs. {formatCurrency(+(ta * (1 - record.deuda.descuento)).toFixed(2))}</span>,
    },
    {
      title: `Tarifa Aseo en ${getCurrency()}`,
      dataIndex: 'tarifaAseo',
      render: (ta, record) =>
        record.deuda.exo ? (
          <b>EXONERADO</b>
        ) : (
          <span>
            {(+(ta * (1 - record.deuda.descuento)) / (taxes?.petro || 1)).toFixed(8)} <Petro />
          </span>
        ),
    },
    {
      title: 'Tarifa Gas',
      dataIndex: 'tarifaGas',
      render: (tg, record) =>
        record.deuda.exo ? <b>EXONERADO</b> : <span>Bs. {formatCurrency(+(tg * (1 - record.deuda.descuento)).toFixed(2))}</span>,
    },
    {
      title: `Tarifa Gas en ${getCurrency()}`,
      dataIndex: 'tarifaGas',
      render: (tg, record) =>
        record.deuda.exo ? (
          <b>EXONERADO</b>
        ) : (
          <span>
            {(+(tg * (1 - record.deuda.descuento)) / (taxes?.petro || 1)).toFixed(8)} <Petro />
          </span>
        ),
    },
  ];

  if (taxes?.SM?.find((sm) => sm.deuda.find((d) => d.descuento > 0))) {
    SMcolumns[5] = Object.assign({}, SMcolumns[4]);
    SMcolumns[4] = Object.assign({}, SMcolumns[3]);
    SMcolumns[3] = Object.assign({}, SMcolumns[2]);
    SMcolumns[2] = Object.assign({}, SMcolumns[1]);
    SMcolumns[1] = {
      title: 'Descuento',
      dataIndex: 'deuda',
      render: (_, record) => <b>{record.deuda.descuento * 100}%</b>,
    };
  }

  const IUcolumns: ColumnsType<any> = [
    {
      title: 'Fecha',
      dataIndex: 'deuda',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      title: 'Impuesto en Bs.',
      dataIndex: 'impuesto',
      render: (imp, record) =>
        record.deuda.exo ? (
          <b>EXONERADO </b>
        ) : (
          <span>Bs. {formatCurrency(+(imp * (1 - record.deuda.descuento)).toFixed(2))}</span>
        ),
    },
    {
      title: `Impuesto en ${getCurrency()}`,
      dataIndex: 'impuesto',
      render: (imp, record) =>
        record.deuda.exo ? (
          <b>EXONERADO</b>
        ) : (
          <span>
            {(+(imp * (1 - record.deuda.descuento)) / (taxes?.petro || 1)).toFixed(8)} <Petro />
          </span>
        ),
    },
  ];

  if (taxes?.IU?.find((sm) => sm.deuda.find((d) => d.descuento > 0))) {
    IUcolumns[3] = Object.assign({}, IUcolumns[2]);
    IUcolumns[2] = Object.assign({}, IUcolumns[1]);
    IUcolumns[1] = {
      title: 'Descuento',
      dataIndex: 'deuda',
      render: (_, record) => <b>{record.deuda.descuento * 100}%</b>,
    };
  }

  const PPcolumns: ColumnsType<any> = [
    {
      title: 'Categoría',
      dataIndex: 'articulo',
      width: '30%',
    },
    {
      title: 'Tipo de Aviso',
      dataIndex: 'subarticulo',
      width: '30%',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      width: '15%',
      render: (qty) => <span>{formatCurrency(qty)}</span>,
    },
    {
      title: 'Costo',
      dataIndex: 'date',
      width: '15%',
      render: (date, record) => (
        <span>Bs. {formatCurrency(getPPItemCost(date, record.articulo, record.subarticulo, record.cantidad))}</span>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'date',
      width: '10%',
      render: (date, record) => (
        <span>
          <Button type='link' onClick={() => removePPItem(date, record.articulo, record.subarticulo)}>
            <DeleteOutlined />
          </Button>
        </span>
      ),
    },
  ];

  const MONOcolumns: ColumnsType<any> = [
    {
      dataIndex: 'date',
      title: 'Fecha',
      render: (date) => (
        <span>
          {firstLetterMayus(date.month)} {date.year}
        </span>
      ),
    },
    {
      dataIndex: 'date',
      title: `Monto (${getCurrency()})`,
      render: () => (
        <span>
          {getMonoTotal()} <Petro />
        </span>
      ),
    },
    {
      dataIndex: 'date',
      title: `Solvencia de Actividades Económicas (${getCurrency()})`,
      render: () => (
        <span>
          {taxes?.MONO?.montoSAE || 0} <Petro />
        </span>
      ),
    },
  ];

  const getAllAESubtotal = () => {
    if (taxes?.AE) {
      try {
        return parseFloat(
          taxes.AE.map((ae) =>
            ae.deuda
              .map((d, i) => {
                if (i === 0) {
                  const doubleMonth = d.year === 2023 && d.month === 'noviembre';
                  const unoPorCienDate = moment().year(2022).month(2).date(31);
                  const dosPorCienDate = moment().year(2023).month(10).date(9);
                  const taxDate = moment().year(d.year).month(mesesNumerico[d.month]).date(1);
                  let taxRate = ae.alicuota;
                  let taxRateDMonth = 0.02;
                  if (!doubleMonth) {
                    if (taxDate.isBefore(unoPorCienDate)) {
                      taxRate = 0.01;
                    } else if (taxDate.isBefore(dosPorCienDate)) {
                      taxRate = 0.02;
                    }
                  }
                  if (doubleMonth) {
                    if (data[d.year][d.month][ae.id][0] === 0) {
                      if (data[d.year][d.month][ae.id][1] === 0) return 0;
                      else return data[d.year][d.month][ae.id][1] * taxRate;
                    } else if (data[d.year][d.month][ae.id][1] === 0) {
                      if (data[d.year][d.month][ae.id][0] === 0) return 0;
                      else return data[d.year][d.month][ae.id][0] * taxRateDMonth;
                    }

                    if (
                      typeof data[d.year][d.month][ae.id][0] !== 'string' &&
                      !!data[d.year][d.month][ae.id][0] &&
                      typeof data[d.year][d.month][ae.id][1] !== 'string' &&
                      !!data[d.year][d.month][ae.id][1]
                    ) {
                      console.log(`KEY 0 ${round(data[d.year][d.month][ae.id][0] * taxRateDMonth, 2)}`);
                      console.log(`KEY 1 ${round(data[d.year][d.month][ae.id][1] * taxRate, 2)}`);
                      return (
                        round(data[d.year][d.month][ae.id][0] * taxRateDMonth, 2) +
                        round(data[d.year][d.month][ae.id][1] * taxRate, 2)
                      );
                    } else throw Error;
                  } else {
                    if (data[d.year][d.month][ae.id] === 0) return 0;
                    if (typeof data[d.year][d.month][ae.id] !== 'string' && !!data[d.year][d.month][ae.id])
                      return data[d.year][d.month][ae.id] * taxRate;
                    else throw Error;
                  }
                } else {
                  return 0;
                }
              })
              ?.reduce((cv, nv) => cv + nv)
          )
            .reduce((c, v) => c + v)
            .toFixed(2)
        ) /*+ parseFloat((taxes.AE[0].costoSolvencia * taxes.AE[getLongerAE()].deuda.length).toFixed(2))*/;
      } catch (e) {
        return -1;
      }
    }
    return -1;
  };

  const getAESubtotal = (month, year) => {
    if (taxes?.AE) {
      try {
        return taxes.AE.filter((ae) => ae.deuda.some((de) => de.month === month && de.year === year))
          .map((ae) => {
            console.log(`GET AE SUBTOTAL ${data[year][month][ae.id]}`);
            const doubleMonth = year === 2023 && month === 'noviembre';
            const unoPorCienDate = moment().year(2022).month(2).date(31);
            const dosPorCienDate = moment().year(2023).month(10).date(9);
            const taxDate = moment().year(year).month(mesesNumerico[month]).date(1);
            let taxRate = ae.alicuota;
            let taxRateDMonth = 0.02;
            if (!doubleMonth) {
              if (taxDate.isBefore(unoPorCienDate)) {
                taxRate = 0.01;
              } else if (taxDate.isBefore(dosPorCienDate)) {
                taxRate = 0.02;
              }
            }
            if (doubleMonth) {
              if (data[year][month][ae.id][0] === 0) {
                if (data[year][month][ae.id][1] === 0) return 0;
                else return data[year][month][ae.id][1] * taxRate;
              } else if (data[year][month][ae.id][1] === 0) {
                if (data[year][month][ae.id][0] === 0) return 0;
                else return data[year][month][ae.id][0] * taxRateDMonth;
              }

              if (
                data[year] &&
                data[year][month] &&
                data[year][month][ae.id] &&
                typeof data[year][month][ae.id][0] !== 'string' &&
                !!data[year][month][ae.id][0] &&
                data[year] &&
                data[year][month] &&
                data[year][month][ae.id] &&
                typeof data[year][month][ae.id][1] !== 'string' &&
                !!data[year][month][ae.id][1]
              )
                return round(data[year][month][ae.id][0] * taxRateDMonth, 2) + round(data[year][month][ae.id][1] * taxRate, 2);
              else throw Error;
            } else {
              if (data[year][month][ae.id] === 0) return 0;
              if (data[year] && data[year][month] && typeof data[year][month][ae.id] !== 'string' && !!data[year][month][ae.id])
                return data[year][month][ae.id] * taxRate;
              else throw Error;
            }
          })
          .reduce((cv, nv) => nv + cv)
          .toFixed(2);
      } catch (e) {
        return 'N/A';
      }
    }
    return 'N/A';
  };

  const getAEGrandTotal = () => getAllAESubtotal();

  const getSMSubtotal = () =>
    taxes && taxes.SM
      ? taxes.SM.reduce(
          (p, v) =>
            p +
            v.deuda.reduce(
              (prev, current) =>
                prev + (!current.exonerado ? v.tarifaAseo * (1 - current.descuento) + v.tarifaGas * (1 - current.descuento) : 0),
              0
            ),
          0
        )
      : 0;

  const getIUSubtotal = () =>
    taxes && taxes.IU
      ? taxes.IU.reduce(
          (p, v) =>
            p +
            v.deuda.reduce(
              (prev, current) => prev + (!current.exonerado ? current.impuestoInmueble * (1 - current.descuento) : 0),
              0
            ),
          0
        )
      : 0;

  const getMonoTotal = () =>
    taxes && taxes.MONO ? taxes.MONO.montoAE + taxes.MONO.montoIU + taxes.MONO.montoPP + taxes.MONO.montoSM : 0;

  const removePPItem = (date, articulo, subarticulo) => {
    const copy = Object.assign({}, PP);
    remove(copy[date], (pp) => pp.subarticulo === subarticulo && pp.articulo === articulo);
    setPP(copy);
  };

  const includingAE = getAllAESubtotal() === -1 && !!taxes?.AE;

  const addPPItem = (date) => {
    if (!article || !subarticle || !quantity) {
      message.error('Debe ingresar el subarticulo que desea declarar con su respectiva cantidad.');
    } else if (PP[date] && !!PP[date].find((pp) => pp.articulo === article[date] && pp.subarticulo === subarticle[date])) {
      message.error('Ya ha creado una declaracion para este subarticulo.');
    } else {
      PP[date]
        ? setPP({
            ...PP,
            [date]: [
              ...PP[date],
              { articulo: `${article[date]}`, subarticulo: `${subarticle[date]}`, cantidad: `${quantity[date]}` },
            ],
          })
        : setPP({
            ...PP,
            [date]: [{ articulo: `${article[date]}`, subarticulo: `${subarticle[date]}`, cantidad: `${quantity[date]}` }],
          });
      setQuantity(undefined);
      setArticle(undefined);
      setSubarticle(undefined);
    }
  };

  const getPPSubtotal = (date) => {
    if (PP[date] ? PP[date].length === 0 : true) return 0;
    return parseFloat(
      PP[date]
        .map((pp) => {
          const subArt = taxes?.PP?.articulos
            .find((ord) => ord.nombreArticulo === pp.articulo)
            ?.subarticulos.find((sub) => sub.nombreSubarticulo === pp.subarticulo);
          if (subArt?.parametro === 'UNIDADES') return pp.cantidad * (subArt ? subArt.costo : 0);
          else if (pp.cantidad <= 10000) return pp.cantidad * (subArt ? subArt.costo : 0);
          else return pp.cantidad * (subArt && subArt.costoAlto ? subArt.costoAlto : 0);
        })
        .reduce((c, v) => c + v)
        .toFixed(2)
    );
  };

  const getPPItemCost = (date, art, sub: string, qty) => {
    if (!PP[date]) return 0;
    return parseFloat(
      (() => {
        const subArt = taxes?.PP?.articulos
          .find((ord) => ord.nombreArticulo === art)
          ?.subarticulos.find((s) => s.nombreSubarticulo === sub);
        if (subArt?.parametro === 'UNIDADES') return qty * (subArt ? subArt.costo : 0);
        else if (qty <= 10000) return qty * (subArt ? subArt.costo : 0);
        else return qty * (subArt && subArt.costoAlto ? subArt.costoAlto : 0);
      })().toFixed(2)
    );
  };

  const getPPTotal = () => {
    const dates = taxes?.PP?.deuda.map((d) => d.month + d.year);
    return taxes
      ? parseFloat(
          dates
            ?.map((d) => getPPSubtotal(d))
            .reduce((c, v) => c + v)
            .toFixed(2) || '0'
        )
      : // + parseFloat(taxes.montoAcarreado.PP.monto.toFixed(2))
        0;
  };

  const getPanelSubtitle = (month, year) => {
    const isDeclared = isNaN(getAESubtotal(month, year) as any);
    return (
      <span>
        {firstLetterMayus(month)} {year}
        <CheckCircleFilled style={{ color: isDeclared ? 'gray' : 'green', marginLeft: 5 }} />
      </span>
    );
  };

  const getLongerAE = (): number => {
    const id = taxes?.AE?.sort((a, b) => {
      if (a.deuda.length > b.deuda.length) return -1;
      if (a.deuda.length < b.deuda.length) return 1;
      return 0;
    })[0]?.id;
    return taxes?.AE?.findIndex((ae) => ae.id === id) || 0;
  };

  const getFormat1 = async () => {
    try {
      const response = await axios.get(
        `${server}/branches/format/1x100/${auth?.user?.contribuyente?.id || taxes?.contribuyente}`,
        { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `1x100.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al descargar el formato');
    }
  };

  const getFormat2 = async () => {
    try {
      const response = await axios.get(
        `${server}/branches/format/2x100/${auth?.user?.contribuyente?.id || taxes?.contribuyente}`,
        { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `2x100.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al descargar el formato');
    }
  };

  const getFormat1000 = async () => {
    try {
      const response = await axios.get(
        `${server}/branches/format/1x1000/${auth?.user?.contribuyente?.id || taxes?.contribuyente}`,
        { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `1x1000.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al descargar el formato');
    }
  };

  return (
    <ConditionalWrapper
      condition={width > 778}
      wrapper={(children) => (
        <Card
          style={{ height: '100%' }}
          title={auth.user?.tipoUsuario === 3 ? 'Liquidar Impuestos' : 'Declarar Impuestos'}
          bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
          headStyle={{
            height: 64,
            backgroundColor: thm.primaryColor,
            padding: width < 992 ? '0 10px' : '0 20px',
            color: 'white',
          }}
        >
          {children}
        </Card>
      )}
    >
      <React.Fragment>
        {(taxes || (auth.user?.tipoUsuario === 4 && auth.user?.contribuyente)) && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Text strong>
                Razón Social: {taxes?.razonSocial.replace(' null', '') || auth.user?.contribuyente?.razonSocial}
              </Typography.Text>
              <Typography.Text strong>
                Documento: {active === 'JURIDICO' ? nacionalidadRif : nacionalidad}-
                {data.documento || auth.user?.contribuyente?.documento}
                {taxes && data.rim && (
                  <React.Fragment>
                    <br />
                    ID: {data.rim}
                  </React.Fragment>
                )}
              </Typography.Text>
            </div>
            <Row style={{ display: 'flex' }}>
              <Col>
                <Row>
                  <Typography.Text>Periodos anteriores abril 2022</Typography.Text>
                </Row>
                <Row>
                  <Button
                    icon={<DownloadOutlined />}
                    target='_blank'
                    type='primary'
                    style={{ marginTop: 0 }}
                    download
                    onClick={() => getFormat1()}
                  >
                    Descargar Formato 1x100
                  </Button>
                </Row>
              </Col>
              <Col style={{ marginLeft: '10px', marginRight:'10px' }}>
                <Row>
                  <Typography.Text>Periodos a partir de abril 2022 al 8 de noviembre 2023</Typography.Text>
                </Row>
                <Row>
                  <Button
                    icon={<DownloadOutlined />}
                    target='_blank'
                    type='primary'
                    style={{ marginTop: 0 }}
                    download
                    onClick={() => getFormat2()}
                  >
                    Descargar Formato 2x100
                  </Button>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Typography.Text>Periodos a partir del 9 de noviembre 2023</Typography.Text>
                </Row>
                <Row>
                  <Button
                    icon={<DownloadOutlined />}
                    target='_blank'
                    type='primary'
                    style={{ marginTop: 0 }}
                    download
                    onClick={() => getFormat1000()}
                  >
                    Descargar Formato 1x1000
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Form
          form={form}
          layout='vertical'
          style={{ padding: width < 992 ? '0 10px' : '0px 20px' }}
          onFinish={() => handleSubmit()}
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          {!taxes && (
            <React.Fragment>
              {auth.user?.tipoUsuario !== 4 ? (
                // <Tabs
                //   defaultActiveKey='JURIDICO'
                //   activeKey={active}
                //   onChange={(e) => (!loading ? setActive(e as any) : () => {})}
                // >
                //   <Tabs.TabPane key='JURIDICO' tab={<span>Jurídico</span>}>
                <Row gutter={24}>
                  {loading && (
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Spin
                        tip='Consultando Impuestos...'
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                      />
                    </Col>
                  )}
                  {!loading && (
                    <React.Fragment>
                      <Col xs={24} md={12} xl={8}>
                        <Form.Item label='R.I.F.' name='documento' normalize={Utils.normalize.toUpper}>
                          <Input placeholder='R.I.F.' addonBefore={rifAddon} />
                        </Form.Item>
                      </Col>
                      {/* <Col xs={24} md={12} xl={8}>
                            <Form.Item label='Código de Sucursal' name='rim' normalize={Utils.normalize.isCNumber}>
                              <Input placeholder='Código de Sucursal' />
                            </Form.Item>
                          </Col> */}
                      <Col span={24}>
                        <Button type='primary' onClick={form.submit}>
                          Consultar
                        </Button>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              ) : (
                // </Tabs.TabPane>
                /* <Tabs.TabPane key='NATURAL' tab={<span>Natural</span>}>
                    <Row gutter={24}>
                      {loading && (
                        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Spin
                            tip='Consultando Impuestos...'
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                          />
                        </Col>
                      )}
                      {!loading && (
                        <React.Fragment>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='Cedula de Identidad' name='documento' normalize={Utils.normalize.isCNumber}>
                              <Input placeholder='Cedula' addonBefore={cedulaAddon} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} xl={8}>
                            <Form.Item label='Documento de Identidad.' name='rim' normalize={Utils.normalize.isCNumber}>
                              <Input placeholder='Documento de Identidad.' />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Button type='primary' onClick={form.submit}>
                              Consultar
                            </Button>
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                  </Tabs.TabPane> */
                /* </Tabs> */
                <Row>
                  {loading ? (
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Spin
                        tip='Consultando Impuestos...'
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
                      />
                    </Col>
                  ) : (
                    (auth?.user?.tipoUsuario !== 4 ||
                      (auth?.user?.tipoUsuario === 4 && (auth?.user?.contribuyente?.sucursales?.length || 0) > 1)) && (
                      <React.Fragment>
                        <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                          <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                            Sucursal
                          </Typography.Title>
                        </Divider>

                        <Col xs={24} md={24} xl={24}>
                          <Form.Item
                            label='Sucursal'
                            name='rim'
                            rules={[{ required: false, message: 'Debe ingresar la sucursal' }]}
                          >
                            <Select placeholder='Seleccione la sucursal'>
                              {auth?.user?.contribuyente?.sucursales?.map(s => 
                                <Select.Option value={s.referenciaMunicipal} key={s.id}>{s.referenciaMunicipal} - {s.denomComercial} - {s.email} - {s.nombreRepresentante} - {s.direccion}</Select.Option>  
                              )}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Button type='primary' onClick={form.submit}>
                            Consultar
                          </Button>
                        </Col>
                      </React.Fragment>
                    )
                  )}
                </Row>
              )}
            </React.Fragment>
          )}
          {taxes && (
            <React.Fragment>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Histórico de Declaraciones
                </Typography.Title>
              </Divider>
              <Button
                href={`${server}/settlements/accountStatement/${taxes.contribuyente}?tipoContribuyente=${active}&referencia=${
                  data?.rim ? data?.rim : auth.user?.contribuyente?.documento
                }`}
                target='_blank'
                icon={<DownloadOutlined />}
                type='primary'
                style={{ marginTop: 0 }}
              >
                Descargar
              </Button>
              {/* {(parseFloat(taxes.creditoFiscal || '0') > 0 || parseFloat(taxes.creditoFiscalRetencion || '0') > 0) && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Credito Fiscal
                    </Typography.Title>
                  </Divider>
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <Typography.Text>
                        <b>Disponible por Compensación:</b> Bs. {formatCurrency(parseFloat(taxes.creditoFiscal || '0'))}
                      </Typography.Text>
                      <Col span={24}>
                        <Typography.Text>
                          <b>Disponible por Retención:</b> Bs. {formatCurrency(parseFloat(taxes.creditoFiscalRetencion || '0'))}
                        </Typography.Text>
                      </Col>
                    </Col>
                  </Row>
                </>
              )} */}
              {taxes.usuarios?.length > 0 && auth.user?.tipoUsuario !== 4 && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Usuario de S.U.T.
                    </Typography.Title>
                  </Divider>
                  <UserSelect users={taxes.usuarios} form={form} />
                </>
              )}
              {!taxes.esMonotributo && (
                <>
                  {(taxes.AE || taxes.montoAcarreado.AE.monto > 0) && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Impuesto del 1x1000
                        </Typography.Title>
                      </Divider>
                      {/* {!taxes?.esAgenteRetencion && !taxes?.esContribuyenteTop && (
                        <Checkbox
                          checked={includeAE}
                          style={{ marginBottom: 15 }}
                          onChange={(e) => setIncludeAE(e.target.checked)}
                        >
                          Incluir impuesto del 2x100 en la liquidación actual
                        </Checkbox>
                      )} */}
                      {includeAE && (
                        <ConditionalWrapper
                          wrapper={(children) => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}
                          condition={width > 776}
                        >
                          <Collapse accordion style={{ border: '1px solid #f0f0f0' }}>
                            {taxes.AE &&
                              taxes.AE.length > 0 &&
                              taxes.AE[getLongerAE()]?.deuda?.map((d, i) => {
                                if (i === 0) {
                                  const ref = React.createRef<HTMLInputElement>();
                                  let conditionalArr: Array<any> = [];
                                  const doubleMonth = d.year === 2023 && d.month === 'noviembre';
                                  if (doubleMonth) {
                                    const deuda = taxes.AE?.filter((ae) =>
                                      ae.deuda.some((de) => de.month === d.month && de.year === d.year)
                                    );
                                    console.log(deuda);
                                    if (deuda && deuda.length > 0) {
                                      conditionalArr.push(deuda[0]);
                                      conditionalArr.push(deuda[0]);
                                    }
                                    console.log(conditionalArr);
                                  }
                                  return (files && !files[`${d.month + d.year}`]) || !files ? (
                                    <Collapse.Panel
                                      key={d.month + d.year}
                                      header={getPanelSubtitle(d.month, d.year)}
                                      style={{ border: '1px solid #f0f0f0' }}
                                    >
                                      <Table
                                        columns={AEcolumns}
                                        dataSource={
                                          doubleMonth && conditionalArr
                                            ? conditionalArr.map((ae, key) => {
                                                console.log(ae);
                                                let taxName = 'Impuesto 1x1000';
                                                if (key === 0) {
                                                  taxName = 'Impuesto 2x100';
                                                }
                                                ae.date = { month: d.month, year: d.year, exo: d.exonerado };
                                                ae.nombreActividad = taxName;
                                                ae.key = key;
                                                return Object.assign({}, ae);
                                              })
                                            : taxes.AE?.filter((ae) =>
                                                ae.deuda.some((de) => de.month === d.month && de.year === d.year)
                                              ).map((ae, key) => {
                                                console.log(ae);
                                                const unoPorCienDate = moment().year(2022).month(2).date(31);
                                                const dosPorCienDate = moment().year(2023).month(10).date(9);
                                                const taxDate = moment().year(d.year).month(mesesNumerico[d.month]).date(1);
                                                let taxName = 'Impuesto 1x1000';
                                                if (taxDate.isBefore(unoPorCienDate)) {
                                                  taxName = 'Impuesto 1x100';
                                                } else if (taxDate.isBefore(dosPorCienDate)) {
                                                  taxName = 'Impuesto 2x100';
                                                }
                                                ae.date = { month: d.month, year: d.year, exo: d.exonerado };
                                                ae.nombreActividad = taxName;
                                                ae.key = key;
                                                return Object.assign({}, ae);
                                              })
                                        }
                                        bordered
                                        pagination={false}
                                      />
                                      <Descriptions
                                        layout={width > 778 ? 'horizontal' : 'vertical'}
                                        bordered
                                        style={{ border: '1px solid #f0f0f0' }}
                                      >
                                        <Descriptions.Item style={{ width: '0px' }}>
                                          <input
                                            hidden
                                            ref={ref}
                                            type='file'
                                            onChange={(e) => handleFiles(e, `${d.month + d.year}`, i)}
                                          />
                                          <Button
                                            type='primary'
                                            style={{ marginBottom: 10 }}
                                            onClick={() => ref.current?.click()}
                                          >
                                            Cargar soporte de la declaración
                                          </Button>
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Sub-Total'>
                                          {isNaN(getAESubtotal(d.month, d.year) as any)
                                            ? 'N/A'
                                            : `Bs. ${formatCurrency(parseFloat(getAESubtotal(d.month, d.year) || '0'))}`}
                                        </Descriptions.Item>
                                        {/* <Descriptions.Item label='Costo por Solvencia'>
                                        {taxes?.AE && taxes?.AE[0]
                                          ? `Bs. ${formatCurrency(taxes.AE[0].costoSolvencia)} (${solvencia} Petros)`
                                          : 'N/A'}
                                      </Descriptions.Item> */}
                                        <Descriptions.Item label='Total'>
                                          {isNaN(getAESubtotal(d.month, d.year) as any) || !taxes?.AE
                                            ? 'N/A'
                                            : `Bs. ${formatCurrency(
                                                parseFloat(getAESubtotal(d.month, d.year) || '0') /*+ taxes.AE[0].costoSolvencia*/
                                              )}`}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Collapse.Panel>
                                  ) : (
                                    <Collapse.Panel
                                      key={d.month + d.year}
                                      header={getPanelSubtitle(d.month, d.year)}
                                      style={{ border: '1px solid #f0f0f0' }}
                                    >
                                      <Table
                                        columns={AEcolumns}
                                        dataSource={
                                          doubleMonth && conditionalArr
                                            ? conditionalArr.map((ae, key) => {
                                                console.log(ae);
                                                let taxName = 'Impuesto 1x1000';
                                                if (key === 0) {
                                                  taxName = 'Impuesto 2x100';
                                                }
                                                ae.date = { month: d.month, year: d.year, exo: d.exonerado };
                                                ae.nombreActividad = taxName;
                                                ae.key = key;
                                                return Object.assign({}, ae);
                                              })
                                            : taxes.AE?.filter((ae) =>
                                                ae.deuda.some((de) => de.month === d.month && de.year === d.year)
                                              ).map((ae, key) => {
                                                console.log(ae);
                                                const unoPorCienDate = moment().year(2022).month(2).date(31);
                                                const dosPorCienDate = moment().year(2023).month(10).date(9);
                                                const taxDate = moment().year(d.year).month(mesesNumerico[d.month]).date(1);
                                                let taxName = 'Impuesto 1x1000';
                                                if (taxDate.isBefore(unoPorCienDate)) {
                                                  taxName = 'Impuesto 1x100';
                                                } else if (taxDate.isBefore(dosPorCienDate)) {
                                                  taxName = 'Impuesto 2x100';
                                                }
                                                ae.date = { month: d.month, year: d.year, exo: d.exonerado };
                                                ae.nombreActividad = taxName;
                                                ae.key = key;
                                                return Object.assign({}, ae);
                                              })
                                        }
                                        bordered
                                        pagination={false}
                                      />
                                      <Descriptions
                                        layout={width > 778 ? 'horizontal' : 'vertical'}
                                        bordered
                                        style={{ border: '1px solid #f0f0f0' }}
                                      >
                                        <Descriptions.Item label='Sub-Total'>
                                          {isNaN(getAESubtotal(d.month, d.year) as any)
                                            ? 'N/A'
                                            : `Bs. ${formatCurrency(parseFloat(getAESubtotal(d.month, d.year) || '0'))}`}
                                        </Descriptions.Item>
                                        {/* <Descriptions.Item label='Costo por Solvencia'>
                                        {taxes?.AE && taxes?.AE[0]
                                          ? `Bs. ${formatCurrency(taxes.AE[0].costoSolvencia)} (${solvencia} Petros)`
                                          : 'N/A'}
                                      </Descriptions.Item> */}
                                        <Descriptions.Item label='Total'>
                                          {isNaN(getAESubtotal(d.month, d.year) as any) || !taxes?.AE
                                            ? 'N/A'
                                            : `Bs. ${formatCurrency(
                                                parseFloat(getAESubtotal(d.month, d.year) || '0') /*+ taxes.AE[0].costoSolvencia*/
                                              )}`}
                                        </Descriptions.Item>
                                      </Descriptions>
                                      <Card
                                        cover={<img alt='soporte' ref={soportesRef.current[i]} />}
                                        bodyStyle={{
                                          padding: 10,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                        }}
                                        // onClick={() => handlePreview(files[`${d.month+d.year}`])}
                                      >
                                        <Typography.Text strong style={{ textAlign: 'center' }}>
                                          {`${d.month + d.year}`}
                                        </Typography.Text>
                                        <Button
                                          type='link'
                                          onClick={() => deleteFile(`${d.month + d.year}`, i)}
                                          style={{ textAlign: 'right', width: '100%', color: 'red' }}
                                        >
                                          Eliminar
                                          <DeleteOutlined />
                                        </Button>
                                      </Card>
                                    </Collapse.Panel>
                                  );
                                }
                              })}
                          </Collapse>
                          <Row gutter={0} style={{ marginTop: 20 }}>
                            {taxes.montoAcarreado.AE.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    Monto de la Ultima Declaración Vigente
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto (${taxes.montoAcarreado.AE.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.AE.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.AE.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                                <Col xs={0} xl={12} />
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '0px 1px 1px 1px solid #f0f0f0' }}
                                  >
                                    {/* <Descriptions.Item
                                      label={`Costo por Solvencia (${taxes.montoAcarreado.AE.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.AE.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.AE ? taxes.AE[0].costoSolvencia : 0)}
                                    </Descriptions.Item> */}
                                  </Descriptions>
                                </Col>
                                <Col xs={0} xl={12} />
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total de la Declaración
                              </Typography.Text>
                            </Divider>
                            <Col xs={24} xl={12}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total`} span={6}>
                                  {getAllAESubtotal() === -1 ? 'N/A' : `Bs. ${formatCurrency(getAEGrandTotal())}`}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                            <Col xs={0} xl={12} />
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                  {(taxes.SM || taxes.montoAcarreado.SM.monto > 0) && (
                    <React.Fragment>
                      {/* <Divider orientation='left' style={{ marginLeft: -20 }}>
                  <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                    Servicios Municipales
                  </Typography.Title>
                </Divider>
                {(!taxes?.esAgenteRetencion && !taxes?.esContribuyenteTop) &&
                  <Checkbox checked={includeSM} style={{ marginBottom: 15 }} onChange={e => setIncludeSM(e.target.checked)}>
                    Incluir Servicios Municipales en la liquidación actual
                  </Checkbox>
                  } */}
                      {/* {includeSM &&
                <ConditionalWrapper condition={width > 778} wrapper={children => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}>
                  {taxes.SM?.map((sm, i) => 
                    <React.Fragment key={sm.id}>
                      <Typography.Text strong>{sm.codCat ? `Código Catastral: ${sm.codCat}` : 'INMUEBLE SIN INFORMACIÓN CATASTRAL'}</Typography.Text>
                      <Table columns={SMcolumns} dataSource={taxes.SM ? sm?.deuda.map((d, key) => 
                        ({
                          deuda: { month: d.month, year: d.year, exo: d.exonerado, descuento: d.descuento },
                          tarifaAseo: taxes.SM ? sm.tarifaAseo : 0,
                          tarifaGas: taxes.SM ? sm.tarifaGas : 0,
                          key
                        })) : []} bordered pagination={false} style={{ marginTop: 15, marginBottom: 15 }} />
                      <Typography.Text strong>Total del Inmueble para Aseo: Bs. {formatCurrency(+((sm.deuda.reduce((p, v) => p + (!v.exonerado ? (sm.tarifaAseo * (1 - v.descuento)) : 0), 0)).toFixed(2)))}</Typography.Text><br />
                      <Typography.Text strong>Total del Inmueble para Gas: Bs. {formatCurrency(+((sm.deuda.reduce((p, v) => p + (!v.exonerado ? (sm.tarifaGas * (1 - v.descuento)) : 0), 0)).toFixed(2)))}</Typography.Text><br />
                      {i !== (taxes?.IU?.length || 0) - 1 && <Divider />}
                    </React.Fragment>
                  )}
                  <Row gutter={0} style={{ marginTop: 20 }}>
                    {taxes.montoAcarreado.SM.monto > 0 && 
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                          {width > 778 ? 'Monto por Ultima Facturación Vigente de Servicios Municipales' : 'MoMonto por Ultima Facturación Vigente'}
                        </Typography.Text>
                      </Divider>
                      <Col xs={24} xl={12}>
                        <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                          <Descriptions.Item label={`Monto por Ultima Facturación (${taxes.montoAcarreado.SM.fecha.month.toUpperCase()} ${taxes.montoAcarreado.SM.fecha.year})`}>
                            Bs. {formatCurrency(taxes.montoAcarreado.SM.monto)}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </React.Fragment>}
                    <Divider orientation='left' style={{ marginLeft: -20 }}>
                      <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                        Total de la Facturación
                      </Typography.Text>
                    </Divider>
                    {!taxes?.esAgenteRetencion &&
                    <Col span={12} style={{ marginBottom: 15 }}>
                      <Checkbox value={esAgenteSENIAT} onChange={(e) => setEsAgenteSENIAT(e.target.checked)}>Es agente de retención especial SENIAT</Checkbox>
                    </Col>}
                    <Col span={24}>
                      <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                        <Descriptions.Item label='Sub-Total'>
                          Bs. {formatCurrency(getSMSubtotal())}
                        </Descriptions.Item>
                        <Descriptions.Item label={!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 'I.V.A.' : 'I.V.A. (75% Retenido)'}>
                          Bs. {formatCurrency(parseFloat((getSMSubtotal() * (!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 0.16 : 0.04)).toFixed(2)))}
                        </Descriptions.Item>
                        <Descriptions.Item label={`Total`}>
                          Bs. {formatCurrency(Math.round(parseFloat(((getSMSubtotal() * (!(esAgenteSENIAT || taxes.esAgenteRetencion) ? 1.16 : 1.04)) + taxes.montoAcarreado.SM.monto).toFixed(2))))}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col xs={0} xl={12} />
                  </Row>
                </ConditionalWrapper>} */}
                    </React.Fragment>
                  )}
                  {(taxes.IU || taxes.montoAcarreado.IU.monto > 0) && (
                    <React.Fragment>
                      <Divider orientation='left' style={{ marginLeft: -20 }}>
                        <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                          Inmuebles Urbanos
                        </Typography.Title>
                      </Divider>
                      <Checkbox checked={includeIU} style={{ marginBottom: 15 }} onChange={(e) => setIncludeIU(e.target.checked)}>
                        Incluir Inmuebles Urbanos en la liquidación actual
                      </Checkbox>
                      {!includeIU && includingAE && (
                        <Alert
                          message='Debe incluir y declarar Actividades Económicas para poder calcular los impuestos de Inmuebles Urbanos'
                          style={{ fontWeight: 'bold' }}
                          type='warning'
                        />
                      )}
                      {includeIU && (
                        <ConditionalWrapper
                          condition={width > 778}
                          wrapper={(children) => (
                            <Card
                              bodyStyle={{
                                border: '1px solid #f0f0f0',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}
                            >
                              {children}
                            </Card>
                          )}
                        >
                          {fetchingIU && (
                            <Spin
                              tip='Calculando...'
                              indicator={
                                <LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />
                              }
                            />
                          )}
                          {taxes.IU?.map((iu, i) => (
                            <React.Fragment key={iu.id}>
                              <Typography.Text strong>Código Catastral: {iu.codCat}</Typography.Text>
                              <Table
                                columns={IUcolumns}
                                dataSource={iu.deuda?.map((d, key) => ({
                                  deuda: { month: d.month, year: d.year, exo: d.exonerado, descuento: d.descuento },
                                  impuesto: d.impuestoInmueble.toFixed(2),
                                  key,
                                }))}
                                bordered
                                pagination={false}
                                style={{ marginTop: 15, marginBottom: 15 }}
                              />
                              <Typography.Text strong>
                                Total del Inmueble: Bs.{' '}
                                {formatCurrency(
                                  iu.deuda.reduce((p, v) => p + (!v.exonerado ? v.impuestoInmueble * (1 - v.descuento) : 0), 0)
                                )}
                              </Typography.Text>
                              {i !== (taxes?.IU?.length || 0) - 1 && <Divider />}
                            </React.Fragment>
                          ))}
                          <Row gutter={0} style={{ marginTop: 20 }}>
                            {taxes.montoAcarreado.IU.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    {width > 778
                                      ? 'Monto por Ultimo Impuesto Vigente de Inmuebles Urbanos'
                                      : 'Monto por Ultimo Impuesto Vigente'}
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto por Ultimo Impuesto (${taxes.montoAcarreado.IU.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.IU.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.IU.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total del Impuesto
                              </Typography.Text>
                            </Divider>
                            <Col xl={12} xs={24}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total`}>
                                  Bs. {formatCurrency(Math.round(+(getIUSubtotal() + taxes.montoAcarreado.IU.monto).toFixed(2)))}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                            <Col xs={0} xl={12} />
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                  {(taxes.PP || taxes.montoAcarreado.PP.monto > 0) && (
                    <React.Fragment>
                      {/* <Divider orientation='left' style={{ marginLeft: -20 }}>
                  <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                    Publicidad y Propaganda
                  </Typography.Title>
                </Divider> 
                <Checkbox checked={includePP} style={{ marginBottom: 15 }} onChange={e => setIncludePP(e.target.checked)}>Incluir Publicidad y Propaganda en la liquidación actual</Checkbox> */}
                      {includePP && (
                        <ConditionalWrapper
                          condition={width > 778}
                          wrapper={(children) => <Card bodyStyle={{ border: '1px solid #f0f0f0' }}>{children}</Card>}
                        >
                          {taxes.PP && taxes.PP.deuda.length > 0 && (
                            <Tabs>
                              {taxes.PP &&
                                taxes.PP.deuda.length > 0 &&
                                taxes.PP.deuda.map((pp, key) => (
                                  <Tabs.TabPane tab={`${firstLetterMayus(pp.month)} ${pp.year}`} key={pp.month + pp.year + key}>
                                    <Row gutter={24}>
                                      <Col xs={24} xl={12}>
                                        <Select
                                          placeholder='Categoría'
                                          value={article ? article[pp.month + pp.year] : undefined}
                                          onChange={(e) => setArticle({ ...article, [pp.month + pp.year]: e })}
                                        >
                                          {taxes.PP?.articulos.map((art) => (
                                            <Select.Option value={art.nombreArticulo}>{art.nombreArticulo}</Select.Option>
                                          ))}
                                        </Select>
                                      </Col>
                                      <Col xs={24} xl={12}>
                                        <Select
                                          placeholder='Tipo de Aviso'
                                          value={subarticle ? subarticle[pp.month + pp.year] : undefined}
                                          onChange={(e) => setSubarticle({ ...subarticle, [pp.month + pp.year]: e.toString() })}
                                        >
                                          {taxes.PP?.articulos
                                            .find((art) => (article ? art.nombreArticulo === article[pp.month + pp.year] : false))
                                            ?.subarticulos?.map((subart) => (
                                              <Select.Option value={subart.nombreSubarticulo}>
                                                {subart.nombreSubarticulo}
                                              </Select.Option>
                                            ))}
                                        </Select>
                                      </Col>
                                      <Col xs={12} xl={6} style={{ marginTop: 10 }}>
                                        <Input
                                          placeholder='Cantidad'
                                          value={quantity ? quantity[pp.month + pp.year] : undefined}
                                          onChange={(q) =>
                                            setQuantity({ ...quantity, [pp.month + pp.year]: parseInt(q.target.value) })
                                          }
                                          type='numeric'
                                        />
                                      </Col>
                                      <Col xs={12} xl={6} style={{ marginTop: 10 }}>
                                        <Button type='primary' onClick={() => addPPItem(pp.month + pp.year)}>
                                          Agregar a Declaración
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Table
                                      columns={PPcolumns}
                                      dataSource={
                                        PP
                                          ? PP[pp.month + pp.year]?.map((p, key) => {
                                              p.date = pp.month + pp.year;
                                              p.key = key;
                                              return p;
                                            })
                                          : []
                                      }
                                      bordered
                                      pagination={false}
                                      style={{ marginTop: 15 }}
                                    />
                                    <Row>
                                      <Col xs={0} xl={12} />
                                      <Col xs={24} xl={12}>
                                        <Descriptions
                                          layout={width > 778 ? 'horizontal' : 'vertical'}
                                          bordered
                                          style={{ border: '1px solid #f0f0f0' }}
                                        >
                                          <DescriptionsItem label='Total'>
                                            Bs. {formatCurrency(getPPSubtotal(pp.month + pp.year))}
                                          </DescriptionsItem>
                                        </Descriptions>
                                      </Col>
                                    </Row>
                                  </Tabs.TabPane>
                                ))}
                            </Tabs>
                          )}
                          <Row>
                            {taxes.montoAcarreado.PP.monto > 0 && (
                              <React.Fragment>
                                <Divider orientation='left' style={{ marginLeft: -20 }}>
                                  <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                    Monto por Ultima Declaración Vigente
                                  </Typography.Text>
                                </Divider>
                                <Col xs={24} xl={12}>
                                  <Descriptions
                                    layout={width > 778 ? 'horizontal' : 'vertical'}
                                    bordered
                                    style={{ border: '1px solid #f0f0f0' }}
                                  >
                                    <Descriptions.Item
                                      label={`Monto por Ultima Declaración (${taxes.montoAcarreado.PP.fecha.month.toUpperCase()} ${
                                        taxes.montoAcarreado.PP.fecha.year
                                      })`}
                                    >
                                      Bs. {formatCurrency(taxes.montoAcarreado.PP.monto)}
                                    </Descriptions.Item>
                                  </Descriptions>
                                </Col>
                              </React.Fragment>
                            )}
                            <Divider orientation='left' style={{ marginLeft: -20 }}>
                              <Typography.Text ellipsis strong style={{ marginLeft: 5 }}>
                                Total de la Declaración
                              </Typography.Text>
                            </Divider>
                            <Col xs={24} xl={12}>
                              <Descriptions
                                layout={width > 778 ? 'horizontal' : 'vertical'}
                                bordered
                                style={{ border: '1px solid #f0f0f0' }}
                              >
                                <Descriptions.Item label={`Total`}>
                                  Bs. {formatCurrency(Math.round(getPPTotal()))}
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                          </Row>
                        </ConditionalWrapper>
                      )}
                    </React.Fragment>
                  )}
                </>
              )}
              {taxes.esMonotributo && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Monotributo
                    </Typography.Title>
                  </Divider>
                  <Typography.Text style={{ display: 'inline-block', marginTop: -20 }} strong>
                    La declaración por monotributo corresponde a las declaraciones de Actividades Económicas, Servicios
                    Municipales, Inmuebles Urbanos y Publicidad y Propaganda. <br />
                    El monto a pagar es de {getMonoTotal()} <Petro /> más {taxes.MONO?.montoSAE || 0} <Petro /> por solvencia de
                    Actividades Económicas por mes de deuda.
                  </Typography.Text>
                  <Row>
                    <Col xs={24} xl={12}>
                      <Table
                        pagination={false}
                        bordered
                        columns={MONOcolumns}
                        dataSource={taxes.MONO?.deuda.map((d, key) => ({
                          date: d,
                          key,
                        }))}
                        style={{ marginTop: 10 }}
                      />
                    </Col>
                  </Row>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Text ellipsis strong style={{ marginLeft: 3 }}>
                      Total de la Declaración
                    </Typography.Text>
                  </Divider>
                  <Row>
                    <Col xs={24} xl={12}>
                      <Descriptions bordered>
                        <Descriptions.Item label='Total'>
                          {(getMonoTotal() + (taxes.MONO?.montoSAE || 0)) * (taxes.MONO?.deuda.length || 0)}{' '}
                          <Petro style={{ marginLeft: 5 }} />
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </>
              )}
              {credit && credit?.length > 0 && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20 }}>
                    <Typography.Text ellipsis strong style={{ marginLeft: 3 }}>
                      Compensaciones Disponibles
                    </Typography.Text>
                  </Divider>
                  <Col xs={24} xl={20}>
                    <Form.Item rules={[{ required: false }]} name='compensaciones'>
                      <Select mode='multiple' placeholder='Compensaciones Disponibles'>
                        {credit?.map((c) => (
                          <Select.Option value={c.id_credito_fiscal} key={c.id_credito_fiscal}>
                            Nro {c.resolucion} - Bs. {c.disponible}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {auth.user?.tipoUsuario === 4 && !isLate && (
                <Button type='primary' onClick={() => setEsVigente(!esVigente)}>
                  {esVigente ? 'Incluir Pago' : 'No Incluir Pago'}
                </Button>
              )}
              {(montoS !== 0 || montoSD !== 0) && (auth.user?.tipoUsuario !== 4 || (auth.user?.tipoUsuario === 4 && (!esVigente || isLate))) && (taxes.AE || taxes.montoAcarreado.AE.monto > 0) && (
                <>
                  <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                    <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                      Datos de Pago
                    </Typography.Title>
                  </Divider>
                  <MultipleTransactions
                    selectedInstitution={institutions?.find((i) => i.id === 9)}
                    form={form}
                    data={data}
                    maxCred={0}
                    files={filesPay}
                    setFiles={setFilesPay}
                    percentTax={true}
                    credit={credit}
                    mineralTax={false}
                    bomberos={false}
                    peajes={false}
                    isLate={isLate}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </Form>
        {taxes &&
          (includeAE || includeSM || includeIU || includePP || taxes.esMonotributo) &&
          !(includeSM && auth?.user?.tipoUsuario === 4) &&
          (taxes.AE || taxes.montoAcarreado.AE.monto > 0) && (
            <Button
              type='primary'
              style={{ marginTop: 10 }}
              loading={creating}
              onClick={() => (taxes.esMonotributo ? declareMono() : declare())}
            >
              Liquidar
            </Button>
          )}
        <Modal
          title='Creando declaración de  impuestos'
          visible={creating}
          cancelButtonProps={{ hidden: true }}
          bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          okButtonProps={{ hidden: true }}
          closable={false}
        >
          <Spin
            tip='Creando declaración...'
            indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
          />
        </Modal>
      </React.Fragment>
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  auth: state.auth,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
});

export default connect(mapStateToProps, { addRequest })(DeclareAnyTaxes);

interface DeclareTaxesProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  addRequest: (request: Request) => any;
}

interface Taxes {
  usuarios: { id: number; correo: string }[];
  contribuyente: string;
  razonSocial: string;
  creditoFiscal: string;
  creditoFiscalRetencion?: string;
  esAgenteRetencion: boolean;
  esContribuyenteTop: boolean;
  esMonotributo: boolean;
  montoAcarreado: {
    AE: {
      monto: number;
      fecha: { month: string; year: number };
    };
    SM: {
      monto: number;
      fecha: { month: string; year: number };
    };
    IU: {
      monto: number;
      fecha: { month: string; year: number };
    };
    PP: {
      monto: number;
      fecha: { month: string; year: number };
    };
  };
  AE?: ActividadEconomica[];
  SM?: ServicioMunicipal[];
  IU?: InmuebleUrbano[];
  PP?: Publicidad;
  MONO?: MonoTax;
  petro: number;
}

interface TaxDeclaration {
  usuario?: number;
  documento: string;
  rim?: string;
  tipoDocumento: string;
  contribuyente?: string;
  esMonotributo: boolean;
  esAgenteRetencion?: boolean;
  esAgenteSENIAT: boolean;
  credit?: { any }[];
  impuestos: {
    ramo: 'SM' | 'PP' | 'IU' | 'AE';
    fechaCancelada: {
      month: string;
      year: number;
    };
    monto: number;
    desglose: any;
  }[];
  totalPagoImpuestos: number;
}

interface MonoTax {
  deuda: Fecha[];
  montoAE: number;
  montoIU: number;
  montoSM: number;
  montoPP: number;
  montoSAE: number;
  aforos: number[];
  date?: Fecha;
  key?: number;
}
